import styled, { css } from 'styled-components'
import { IDepartmentInfoSlice } from '../../prismicTypes'
import Typography from '../../styles/typography'
import Slice from '../Slice'

interface IProps {
  slice: IDepartmentInfoSlice
}

const DepartmentInfo: React.FC<IProps> = ({ slice: { slice_type, primary, items } }) => {
  const { department_title } = primary
  return (
    <Slice sliceType={slice_type}>
      <SCWrapper>
        <SCTitle>{department_title}</SCTitle>
        <SCEmployeeWrapper>
          {items.map(({ employee_name, job_description, email, cell_phone, work_phone }, index) => (
            <SCEmployeeInfo key={`${employee_name}${index}`}>
              <SCEmployeeName>{employee_name}</SCEmployeeName>
              <SCJobDescription>{job_description}</SCJobDescription>
              {email && <SCEmail href={`mailto:${email}`}>{email}</SCEmail>}
              {cell_phone && <SCPhone href={`tel:${cell_phone}`}>{cell_phone}</SCPhone>}
              {work_phone && <SCPhone href={`tel:${work_phone}`}>{work_phone}</SCPhone>}
            </SCEmployeeInfo>
          ))}
        </SCEmployeeWrapper>
      </SCWrapper>
    </Slice>
  )
}

export default DepartmentInfo

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    margin-bottom: 3.5rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 6.5rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 10rem;
    }
  `}
`

const SCEmployeeWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-columns: repeat(2, minmax(50%, 1fr));
      gap: 3rem 1rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: repeat(4, minmax(25%, 1fr));
    }
  `}
`

const SCTitle = styled.h4`
  ${Typography.h4};
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 3rem;
`

const SCEmployeeInfo = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 0;
    }
  `}
`

const SCEmployeeName = styled.h5`
  ${Typography.h5};
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-size: 1.125rem;
`

const SCJobDescription = styled.div`
  ${Typography.buttonText};
  margin-bottom: 1rem;
`

const SCEmail = styled.a`
  ${Typography.pSmall}
`

const SCPhone = styled.a`
  ${Typography.pSmall}
`

import { RichTextBlock } from 'prismic-reactjs'
import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'
import { IPrismicImage } from '../../prismicBaseTypes'
import Typography from '../../styles/typography'
import PrismicRichText from '../PrismicRichText'

interface IProps {
  title: string
  description: RichTextBlock[]

  icon?: IPrismicImage
}

const ThreeColumnIconCard: React.FC<IProps> = ({ title, description, icon }) => (
  <div>
    {icon?.url && (
      <SCIconWrapper>
        <Image src={icon.url} width={icon.dimensions?.width || 0} height={icon.dimensions?.height || 0} alt={title} />
      </SCIconWrapper>
    )}
    <SCTitle>{title}</SCTitle>
    <SCDescriptionWrapper>
      <PrismicRichText richText={description} />
    </SCDescriptionWrapper>
  </div>
)

export default ThreeColumnIconCard

const SCIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`
const SCTitle = styled.h5`
  ${Typography.h5}
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.125rem;
`
const SCDescriptionWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    ${Typography.pSmall};
    color: ${colors.black400};
    p{
      margin: 0;
    }
  `}
`

import styled, { css } from 'styled-components'
import { Translations } from '../../../data/Translations'

const InstallmentHeaderRow: React.FC = () => {
  const { breakdown } = Translations.is

  return (
    <>
      <SCHeaderTableCell style={{ order: 1 }}>{breakdown.payment}</SCHeaderTableCell>
      <SCHeaderTableCell style={{ order: 1 }}>{breakdown.paymentDate}</SCHeaderTableCell>
      <SCHeaderTableCell style={{ order: 1 }}>{breakdown.installment}</SCHeaderTableCell>
      <SCHeaderTableCell style={{ order: 1 }}>{breakdown.interest}</SCHeaderTableCell>
      <SCHeaderTableCell style={{ order: 1 }}>{breakdown.cost}</SCHeaderTableCell>
      <SCHeaderTableCell style={{ order: 1 }}>{breakdown.totalPaid}</SCHeaderTableCell>
      <SCHeaderTableCell isLastColumn style={{ order: 1 }}>
        {breakdown.remainder}
      </SCHeaderTableCell>
    </>
  )
}

export default InstallmentHeaderRow

export const SCHeaderTableCell = styled.div<{ isLastColumn?: boolean }>`
  ${({ isLastColumn, theme: { colors } }) => css`
    box-sizing: border-box;
    flex-grow: 1;

    width: 14.28%; // 7 columns, each cell 1/7 width
    color: ${colors.blue400};
    font-family: 'AreaNormal', sans-serif;
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    border-bottom: 0.0625rem solid rgba(69, 99, 114, 0.6);
    padding: 0.5rem;
    ${isLastColumn && `text-align: right;`}
  `}
`

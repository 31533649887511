import Image from 'next/image'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import { ExtraStyle } from '../../types/Types'

interface IProps {
  title: string
  onClick(): void
  secondary?: boolean
  extrastyle?: ExtraStyle
  disabled?: boolean
}

const Button: React.FC<IProps> = ({ title, onClick, secondary, disabled, extrastyle }) => {
  return (
    <SCButton disabled={disabled} secondary={secondary} onClick={onClick} extrastyle={extrastyle}>
      <SCButtonText>
        {title}
        {!secondary && (
          <SCArrowWrapper>
            <Image src="/svg/right_arrow.svg" width={10} height={10} alt="Right" />
          </SCArrowWrapper>
        )}
      </SCButtonText>
    </SCButton>
  )
}

export default Button

const SCButton = styled.button<{ secondary?: boolean; disabled?: boolean; extrastyle?: ExtraStyle }>`
  ${({ secondary, disabled, extrastyle, theme: { colors } }) => css`
    ${!secondary && `background-color: ${colors.orange400}`};
    ${disabled && `background-color: ${colors.grey200}`};
    border: ${secondary ? `0.0625rem solid ${colors.orange400}` : 'none'};
    border-radius: 0.5rem;

    transition: background-color 0.3s ease-in;

    &:hover {
      background-color: ${disabled ? colors.grey200 : secondary ? colors.orange400 : colors.buttonHover};
    }

    ${extrastyle}
  `}
`

const SCArrowWrapper = styled.span`
  ${({ theme: { breakpoints } }) => css`
    display: none;
    align-self: center;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      display: inline-block;
    }
  `}
`

const SCButtonText = styled.span`
  ${({ theme: { colors, breakpoints } }) => css`
    display: flex;
    ${Typography.buttonText};
    color: ${colors.black400};
    padding: 1.25rem 2rem;
    max-width: 15 srem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      padding: 1.25rem 2rem;
      > span {
        padding-left: 1rem;
      }
    }
    @media screen and (min-width: ${breakpoints.desktopMinPlus}) {
      padding: 1.5rem 2rem;
    }
  `}
`

import Image from 'next/image'
import styled, { css } from 'styled-components'
import { IProcessCards } from '../../prismicTypes'
import Typography from '../../styles/typography'
import MainGridContainer from '../grid/MainGridContainer'
import PrismicRichText from '../PrismicRichText'
import Slice from '../Slice'

interface IProps {
  sliceType: string
  slice: IProcessCards
}

const ProcessCards: React.FC<IProps> = ({ sliceType, slice: { title, process } }) => {
  return (
    <>
      <MainGridContainer>
        <SCTitle>{title}</SCTitle>
      </MainGridContainer>
      <Slice sliceType={sliceType} gridStyle={SCScrollableMobileGrid}>
        <SCWrapper>
          <SCProcessItems>
            {process.map((processItem, index) => (
              <SCProcessItem key={index}>
                <SCImageWrapper>
                  <Image
                    src={processItem.icon.url}
                    height={processItem.icon.dimensions?.height || 0}
                    width={processItem.icon.dimensions?.width || 0}
                    alt={title}
                  />
                  <SCLabel>
                    <SCIndex>{index + 1}</SCIndex>
                  </SCLabel>
                  <SCBorder index={index + 1} />
                </SCImageWrapper>
                <PrismicRichText extraParagraphStyle={SCRichText} richText={processItem.description} />
              </SCProcessItem>
            ))}
          </SCProcessItems>
        </SCWrapper>
      </Slice>
    </>
  )
}

export default ProcessCards

const SCScrollableMobileGrid = css`
  ${({ theme: { breakpoints } }) => css`
    overflow-x: scroll;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      overflow-x: hidden;
    }
  `}
`

const SCTitle = styled.h3`
  ${Typography.h3}
  grid-column: 1 / -1;
  margin-bottom: 7.5rem;
`

const SCProcessItems = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: repeat(4, 1fr);
    }
  `}
`

const SCProcessItem = styled.div`
  ${({ theme: { breakpoints } }) => css`
    min-width: 15rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      min-width: 10rem;
    }
  `}
`

const SCImageWrapper = styled.div`
  position: relative;
  height: 10rem;
  padding-bottom: 3rem;
`

const SCWrapper = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 12.3125rem;
`

const SCBorder = styled.div<{ index: number }>`
  ${({ index, theme: { colors, breakpoints } }) => css`
    position: absolute;
    height: 0;
    width: 100%;
    bottom: 0;
    left: -2rem;
    border-bottom: 1px dashed ${colors.blue200};
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      left: -5rem;
    }
    &::after {
      position: absolute;
      content: '';
      height: 1.5rem;
      bottom: -0.5rem;
      width: 7rem;
      @media screen and (min-width: ${breakpoints.tabletMin}) and (max-width: ${breakpoints.desktopMin}) {
        ${index % 2 !== 0
          ? css`
              background: linear-gradient(270deg, ${colors.white} 0%, rgba(255, 255, 255, 0) 102.7%);
              transform: rotate(-180deg);
              left: -1rem;
            `
          : css`
              background: linear-gradient(270deg, ${colors.white} 0%, rgba(255, 255, 255, 0) 217.7%);
              right: 0;
            `}
      }
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        ${index === 1 &&
        css`
          background: linear-gradient(270deg, ${colors.white} 0%, rgba(255, 255, 255, 0) 102.7%);
          transform: rotate(-180deg);
          left: -1rem;
        `}
        ${index === 4 &&
        css`
          background: linear-gradient(270deg, ${colors.white} 0%, rgba(255, 255, 255, 0) 217.7%);
          right: 0;
        `}
      }
    }
  `}
`

const SCLabel = styled.div`
  ${({ theme: { colors } }) => css`
    ${Typography.inputHeader}
    position: absolute;
    bottom: -1rem;
    left: 0;
    border-radius: 50%;
    border: 1px solid ${colors.blue400};
    height: 2rem;
    width: 2rem;
    background: ${colors.white};
    display: flex;
    justify-content: center;
    z-index: 9;
  `}
`

const SCIndex = styled.span`
  display: inline-block;
  align-self: center;
`

const SCRichText = css`
  ${({ theme: { colors, breakpoints } }) => css`
    ${Typography.h5}
    padding: 1rem;
    color: ${colors.blue400};
    width: 80%;
    font-weight: 900;
    font-size: 1rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      font-size: 1.125rem;
    }
  `}
`

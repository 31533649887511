import React from 'react'
import { IThreeColumnNewsCardSlice } from '../../prismicTypes'
import styled, { css } from 'styled-components'
import Slice from '../Slice'
import Typography from '../../styles/typography'
import NewsArticlePageCard from '../PageCards/NewsArticlePageCard'
import Link from 'next/link'
import { routingPaths } from '../../data/Constants'
import { Translations } from '../../data/Translations'

interface IProps {
  slice: IThreeColumnNewsCardSlice
}
const ThreeColumnNewsCard: React.FC<IProps> = ({
  slice: {
    primary: { overview_title, link_text },
    newsArticlesPreview,
  },
}) => {
  return newsArticlesPreview ? (
    <Slice>
      <SCTitle>{overview_title[0].text}</SCTitle>
      {newsArticlesPreview.map((preview, index) => (
        <SCArticleCard
          key={`${preview.title}-${index}`}
          title={preview.title}
          description={preview.short_description}
          href={preview.href}
          date={preview.published_date}
        />
      ))}
      <SCFullWidthGridContainer>
        <Link href={`/${routingPaths.news}`} passHref>
          <SCLinkTitle>{link_text ?? Translations.is.contentSections.newsArticles.seeAll}</SCLinkTitle>
        </Link>
      </SCFullWidthGridContainer>
    </Slice>
  ) : null
}

export default ThreeColumnNewsCard

const SCTitle = styled.h3`
  ${() => css`
    ${Typography.h3}
    grid-column: 1/-1;
  `}
`
const SCFullWidthGridContainer = styled.div`
  margin-bottom: 5rem;
  margin-top: 2rem;
  grid-column: span 12;
`

const SCLinkTitle = styled.div`
  ${({ theme: { colors } }) => css`
    ${Typography.buttonText}
    text-decoration: underline;
    text-decoration-color: ${colors.blue200};
    cursor: pointer;
    &:hover {
      text-decoration-color: ${colors.blue400};
    }
  `}
`

const SCArticleCard = styled(NewsArticlePageCard)`
  ${({ theme: { breakpoints } }) => css`
    grid-column: span 6;
    margin-right: 4em;
    @media screen and (max-width: ${breakpoints.tabletMax}) {
      grid-column: span 12;
      margin-right: 1em;
    }
  `}
`

import * as React from 'react'
import styled from 'styled-components'
import Typography from '../../styles/typography'
import Paragraph from '../Paragraph'
import PrismicRichText from '../PrismicRichText'
import { RichTextBlock } from 'prismic-reactjs'

export interface ITextCardProps {
  title: string
  description: string | RichTextBlock[]
  date?: Date
}

export function TextCard({ title, description, date }: ITextCardProps) {
  return (
    <div>
      {date && <Paragraph extraStyle={Typography.pBody}>{date}</Paragraph>}

      <SCTitle>{title}</SCTitle>
      {typeof description === 'string' ? (
        <Paragraph extraStyle={Typography.pBody}>{description}</Paragraph>
      ) : (
        <PrismicRichText extraParagraphStyle={Typography.pBody} richText={description} />
      )}
    </div>
  )
}

const SCTitle = styled.h3`
  ${Typography.h3}
  margin-bottom: 1rem;
  margin-top: 1rem;
`

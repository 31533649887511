import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { IFAQCards } from '../../prismicTypes'
import Typography from '../../styles/typography'
import MainGridContainer from '../grid/MainGridContainer'
import { IconPlusSign, IconMinusSign } from '../../public/svg/IconFAQ'
import PrismicRichText from '../PrismicRichText'
import { useRouter } from 'next/router'

interface IProps {
  slice: IFAQCards
  sliceAnchor: string
}
export const FaqCards: React.FC<IProps> = ({ slice: { primary, items }, sliceAnchor }) => {
  const [showAnswer, setShowAnswer] = useState(new Array(items.length).fill(false))
  const router = useRouter()
  /* This contains functions regarding the searchHits, and opening the answers when users click on FAQ searchHits. */
  const containsAnchor = router.asPath.indexOf('#faq_cards') > 0 ? true : false
  const containsFAQAnchor = router.asPath.indexOf('--') > 0 ? true : false
  if (containsAnchor && containsFAQAnchor) {
    const questionID = router.asPath.substr(router.asPath.length - 1)
    showAnswer[parseInt(questionID)] = true
  }

  const handleAnswerClick = (index: number) => {
    setShowAnswer({
      ...showAnswer,
      [index]: !showAnswer[index],
    })
  }
  return (
    <MainGridContainer>
      <SCTitle>{primary.title[0].text}</SCTitle>
      <SCFAQTable>
        {items.map((item, index) => (
          <SCFAQRow onClick={() => handleAnswerClick(index)} key={index}>
            <SCQRow id={sliceAnchor + '--' + index.toString()}>
              <SCFAQQuestion>{item.question[0].text}</SCFAQQuestion>
              <SCAnswerButton>{showAnswer[index] ? <IconMinusSign /> : <IconPlusSign />}</SCAnswerButton>
            </SCQRow>
            {showAnswer[index] && (
              <SCARow key={index}>
                <PrismicRichText richText={item.answer} />
              </SCARow>
            )}
          </SCFAQRow>
        ))}
      </SCFAQTable>
    </MainGridContainer>
  )
}

const SCTitle = styled.h3`
  ${Typography.h3}
  grid-column: 1 / -1;
  margin-top: 0;
`
const SCFAQTable = styled.div`
  ${({ theme: { breakpoints } }) => css`
    align-items: left;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-radius: 1.75rem;
    padding-bottom: 4rem;
    grid-column: 1 / -1;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 10rem;
    }
  `}
`

const SCFAQQuestion = styled.h6`
  display: flex;
  ${Typography.h6};
  flex-grow: 2;
  margin: 2rem 2rem 2rem 0;
  text-align: left;
`

const SCFAQRow = styled.button`
  ${({ theme: { breakpoints, colors } }) => css`
    border-bottom: 0.0625rem solid rgba(69, 99, 114, 0.2);
    :last-child {
      border-bottom: 0;
    }
    transition: all 320ms ease;
    background-color: ${colors.white};
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      &:hover {
        background-color: ${colors.grey100};
      }
    }
  `}
`
const SCQRow = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden; // Or flex might break
    width: 100%;
    padding: 0;
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      padding: 0 1rem;
    }
  `}
`

const SCARow = styled.div`
  ${({ theme: { breakpoints, colors } }) => css`
    ${Typography.pSmall};
    padding-bottom: 3rem;
    opacity: 0.9;
    color: ${colors.black400};
    text-align: left;
    p {
      margin: 0;
    }

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      padding: 1.5rem 3.5rem 3.5rem 3.5rem;
    }
  `}
`

const SCAnswerButton = styled.span`
  ${({ theme: { colors, breakpoints } }) => css`
    align-items: right;
    max-width: 10%;
    align-self: center;
    > svg {
      transition: all 320ms ease;
      fill: white;
      & > path:not(:first-child) {
        stroke: ${colors.blue400};
      }
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        ${SCFAQRow}:hover & {
          fill: ${colors.blue400};
          & > path:not(:first-child) {
            stroke: ${colors.white};
          }
        }
      }
    }
  `}
`

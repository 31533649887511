import { FunctionComponent } from 'react'
import { PrismicLinkProps } from '../../prismicBaseTypes'
import { ExtraStyle } from '../../types/Types'
import { linkResolver } from '../../utils/prismicHelpers'
import { useRouteChange } from '../RoutingProvider'
import Button from './Button'

type Props = {
  link: PrismicLinkProps
  buttonText: string
  secondary?: boolean
  extrastyle?: ExtraStyle
  disabled?: boolean
}

const LinkButton: FunctionComponent<Props> = ({ link, secondary, disabled, buttonText, extrastyle }) => {
  const { handleRouteChange } = useRouteChange()

  const handleButtonClick = () => {
    const isWebLink = link.link_type === 'Web'
    const modifiedLink = isWebLink ? link.url : linkResolver(link)
    handleRouteChange({ url: modifiedLink || '', isNextLink: !isWebLink })
  }
  return (
    <Button
      title={buttonText}
      secondary={secondary}
      disabled={disabled}
      onClick={handleButtonClick}
      extrastyle={extrastyle}
    />
  )
}

export default LinkButton

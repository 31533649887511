import { useSelector } from 'react-redux'
import { css } from 'styled-components'
import { Translations } from '../../../data/Translations'
import { selectErrorMessage } from '../../../store/loan'
import LinkButton from '../../common/LinkButton'

const OverviewButton: React.FC = () => {
  const { calculator } = Translations.is

  const errorMessage = useSelector(selectErrorMessage)

  let href = `/reiknivel/umsoknarferli`
  /* Commented out since it is likely we will need this in the future again.
  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)
  const months = useSelector(selectMonths)
  const query = useRouter().query
  let href = `https://minn.lykill.is/applications/car-loan/${price}/${deposit}/${months}`
  
  if (containsBilasolurParameters(query)) {
    href = `https://minn.lykill.is/applications/car-loan/${query.bid}/${query.cid}/${query.sid}/${price}/${deposit}/${months}`
  }
  */
  return (
    <LinkButton
      buttonText={calculator.applyForALoan}
      extrastyle={SCExtraStyle}
      link={{ link_type: 'Web', url: href }}
      disabled={Boolean(errorMessage)}
    />
  )
}

const SCExtraStyle = css`
  ${({ theme: { breakpoints } }) => css`
    @media screen and (max-width: ${breakpoints.tabletMin}) {
      width: 100%;
      max-width: unset;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        justify-content: center;
      }
    }
  `}
`
export default OverviewButton

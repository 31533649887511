import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import { RichTextBlock } from 'prismic-reactjs'
import { replaceDoubleHyphen } from '../../utils/helpers'
import { IPrismicImage } from '../../prismicBaseTypes'
import Slice from '../Slice'
import PrismicRichText from '../PrismicRichText'

interface IProps {
  label?: string
  title: string
  description: RichTextBlock[]
  children?: React.ReactNode
  image: IPrismicImage
  useH1?: boolean
  alignImageRight?: boolean
  listColumnCount?: number
  hideImageOnTablet?: boolean
  sliceType: string
}

const ImageSection: React.FC<IProps> = ({
  label,
  title,
  description,
  children,
  image,
  sliceType,
  useH1 = false,
  listColumnCount = 1,
  alignImageRight = true,
  hideImageOnTablet = false,
}) => {
  return (
    <Slice sliceType={sliceType} gridStyle={SCImageSection}>
      <SCImageColumn alignImageRight={alignImageRight} hideImageOnTablet={hideImageOnTablet}>
        <SCImage imageUrl={image?.url}></SCImage>
      </SCImageColumn>
      <SCContentColumn alignImageRight={alignImageRight}>
        <div>
          {label && <SCLabel>{label}</SCLabel>}
          {useH1 ? <SCTitleH1>{replaceDoubleHyphen(title)}</SCTitleH1> : <SCTitleH3>{title}</SCTitleH3>}
          <SCDescription listColumnCount={listColumnCount}>
            <PrismicRichText richText={description} />
          </SCDescription>
          {children}
        </div>
      </SCContentColumn>
    </Slice>
  )
}

export default ImageSection

const SCImageSection = css`
  ${({ theme: { breakpoints } }) => css`
    margin: 5rem 0;
    overflow: hidden;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 10rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      overflow: visible;
      margin-bottom: 15rem;
    }
  `}
`
export const SCImageColumn = styled.div<{ alignImageRight: boolean; hideImageOnTablet: boolean }>`
  ${({ alignImageRight, hideImageOnTablet, theme: { breakpoints } }) => css`
    grid-column: 1 / -1;
    margin: 0;
    margin-left: ${alignImageRight ? '-4rem' : '0'};
    margin-right: ${alignImageRight ? '0' : '-4rem'};
    height: 21.5rem;
    display: ${hideImageOnTablet ? 'none' : 'block'};
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: calc(6rem - 1.5rem); /* height of title margin-top subtracted */
      grid-column: ${alignImageRight ? '1 / 7' : '-1 / 1'};
      height: 39rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin: 0;
      margin-left: ${alignImageRight ? '0' : '-6rem'};
      margin-right: ${alignImageRight ? '-4rem' : '0'};
      order: ${alignImageRight ? 2 : 1};
      grid-column: ${alignImageRight ? '7 / 13' : '1 / 7'};
      height: 53rem;
      display: block;
    }
  `}
`

export const SCContentColumn = styled.div<{ alignImageRight: boolean }>`
  ${({ alignImageRight, theme: { breakpoints } }) => css`
    grid-column: 1 / -1;
    margin: 0;
    margin-top: 3rem;
    display: flex;
    align-items: center;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin: 0;
      grid-column: 1 / 5;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin: 5.5rem 0;
      order: ${alignImageRight ? 1 : 2};
      grid-column: ${alignImageRight ? '1 / 6' : '8 / 13'};
    }
  `}
`

const SCLabel = styled.span`
  ${Typography.eyebrow}
`

const SCTitleH1 = styled.h1`
  ${Typography.h1}
  margin: 1.5rem 0;
`

const SCTitleH3 = styled.h1`
  ${Typography.h3}
  margin: 1.5rem 0;
`

const SCImage = styled.div<{ imageUrl: string }>`
  ${({ imageUrl }) => css`
    background-image: url(${imageUrl});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.625rem;
    height: 100%;
  `}
`

const SCDescription = styled.div<{ listColumnCount: number }>`
  ${({ listColumnCount, theme: { colors, breakpoints } }) => css`
    ${Typography.pBody};
    color: ${colors.black400};
    margin-bottom: 2rem;

    & p {
      margin-bottom: 2.5rem;
    }
    & ul {
      columns: ${listColumnCount};
      padding-left: 1.3125rem; /* ul icon positioning */
    }
    & li {
      margin-bottom: 1rem;
    }
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 2.5rem;
    }
  `}
`

import { theme } from '../../styles/globalStyles'

const IconLeaf: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6208 8.36749C9.61466 8.36749 7.98833 9.99382 7.98833 12C7.98833 14.0062 9.61466 15.6325 11.6208 15.6325C14.5801 15.6325 15.3064 11.999 17.0021 11.0306C15.7378 9.33292 13.7374 8.34292 11.6208 8.36749V8.36749Z"
        stroke={theme.colors.green400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.059 11.6048C10.0276 11.9447 8.23736 13.1342 7.13696 14.8752"
        stroke={theme.colors.green400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="12"
        r="9.00375"
        stroke={theme.colors.green400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconLeaf

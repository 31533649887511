import { useEffect, useState } from 'react'
import Image from 'next/image'
import { useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Bubbles from '../Bubbles'
import Typography from '../../styles/typography'
import { IBilasolurResponse } from '../../types'
import { convertNumberToCurrency, getMonthsSinceDate } from '../../utils/helpers'
import {
  updateErrorMessage,
  updatePrice,
  selectErrorMessage,
  selectMonths,
  selectDeposit,
  selectPrice,
} from '../../store/loan'
import IconBilasolur from '../../public/svg/IconBilasolur'
import { calculatorConstants, mediaQueries } from '../../data/Constants'
import { theme } from '../../styles/globalStyles'
import { Translations } from '../../data/Translations'
import { Dispatch } from 'redux'
import { useRouter } from 'next/router'
import useSWR from 'swr'

const MAX_AGE_VEHICLE_IN_MONTHS =
  calculatorConstants.MAX_CONTRACT_COMBINED_WITH_AGE_LENGTH - calculatorConstants.MIN_CONTRACT_LENGTH

const validateVehicleData = (
  dispatch: Dispatch,
  contractLength: number,
  deposit: number,
  price: number,
  vehicleAgeInMonths: number,
  vehiclePriceTooLow: boolean
) => {
  //Rule 0, min price amount is 900.000 ISK
  //Rule 1, vehicle age + contract length <= 108 months, we require at least 10% deposit (Calculator is default set up with this rule)
  //Rule 2, vehicle age + contract length > 108 months, then we require at least 20% deposit (9 Year Rule)
  //Rule 3, vehicle age + contractLength must be under 144 months (12 years)
  //Rule 4, for used vehicles, max loan is 60 months if deposit is under 20% (9 Year Rule addition).
  const { bilasolur } = Translations.is
  /* Rule 0 triggered  */
  if (vehiclePriceTooLow) {
    dispatch(updateErrorMessage(bilasolur.vehiclePriceTooLow))
  } else if (vehicleAgeInMonths > MAX_AGE_VEHICLE_IN_MONTHS) {
    /* Rule 3 triggered */
    dispatch(updateErrorMessage(bilasolur.tooOld))
  } else if (vehicleAgeInMonths + contractLength > calculatorConstants.MAX_CONTRACT_COMBINED_WITH_AGE_LENGTH) {
    /* Rule 3 triggered */
    dispatch(updateErrorMessage(bilasolur.monthsPlusAgeTooMuch))
  } else if (
    vehicleAgeInMonths + contractLength > calculatorConstants.MAX_CONTRACT_LENGTH_NINE_YEAR_RULE &&
    deposit < price * calculatorConstants.MIN_DEPOSIT_PERCENTAGE_FOR_OLD_VEHICLE
  ) {
    /*Rule 2 triggered*/
    dispatch(updateErrorMessage(bilasolur.depositUnderTwentyPercent))
  } else if (
    deposit < price * calculatorConstants.MIN_DEPOSIT_PERCENTAGE_FOR_OLD_VEHICLE &&
    contractLength > calculatorConstants.MAX_CONTRACT_LENGTH_UNDER_MIN_DEPOSIT
  ) {
    /*Rule 4 triggered*/
    dispatch(updateErrorMessage(bilasolur.contractLengthWithMinDepositOverLimit))
  } else {
    dispatch(updateErrorMessage(''))
  }
}
interface IProps {
  onCarInfoUpdate(carInfo: IBilasolurResponse): void
}
const BilasolurCard: React.FC<IProps> = ({ onCarInfoUpdate }) => {
  const [vehiclePriceTooLow, setVehiclePriceTooLow] = useState<boolean>(false)
  const { bilasolur } = Translations.is
  const fetcher = (path: string) => fetch(path).then((res) => res.json())
  const router = useRouter()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(mediaQueries.isMobile)
  const { data, error } = useSWR(
    `/api/bilasolur?bid=${router.query.bid}&cid=${router.query.cid}&sid=${router.query.sid}`,
    fetcher
  )
  const errorMessage = useSelector(selectErrorMessage)
  const contractLength = useSelector(selectMonths)
  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)

  useEffect(() => {
    if (data) {
      setVehiclePriceTooLow(data.Verd < calculatorConstants.MIN_PRICE_AMOUNT)
      onCarInfoUpdate(data)
      validateVehicleData(
        dispatch,
        contractLength,
        deposit,
        price,
        getMonthsSinceDate(data.NyskradurAr, data.NyskradurManudur),
        vehiclePriceTooLow
      )
      dispatch(updatePrice(data.Verd))
    }
  }, [data])

  useEffect(() => {
    if (data) {
      validateVehicleData(
        dispatch,
        contractLength,
        deposit,
        price,
        getMonthsSinceDate(data.NyskradurAr, data.NyskradurManudur),
        vehiclePriceTooLow
      )
    }
  }, [contractLength, deposit])

  if (error) {
    return (
      <SCErrorCard hasError={true}>
        <SCErrorMessage>{bilasolur.error}</SCErrorMessage>
      </SCErrorCard>
    )
  }

  return data ? (
    <>
      <SCErrorCard hasError={Boolean(errorMessage)}>
        <SCErrorMessage>{errorMessage}</SCErrorMessage>
      </SCErrorCard>

      <SCWrapper>
        <SCImage height={isMobile ? 96 : 112} width={isMobile ? 120 : 140} src={data.PhotoUrl} alt="" />
        <SCCarDetails>
          <IconBilasolur />
          <SCCarName>{`${data.Framleidandi} ${data.Gerd}`}</SCCarName>
          <SCCarInfoWrapper>
            {!isMobile && (
              <>
                <SCCarInfo>{data.NyskradurAr}</SCCarInfo>
                <SCCarInfo>{data.Ekinn}</SCCarInfo>
              </>
            )}
            <SCCarInfo>{convertNumberToCurrency(data.Verd)}</SCCarInfo>
          </SCCarInfoWrapper>
        </SCCarDetails>
        {!isMobile && (
          <SCSellerInfo>
            <SCSellerName>{data.Seller.Nafn}</SCSellerName>
            <SCSellerPhone>{data.Seller.Simi}</SCSellerPhone>
          </SCSellerInfo>
        )}
      </SCWrapper>
    </>
  ) : (
    <LoadingWrapper>
      <Bubbles size={isMobile ? 'small' : 'large'} color={theme.colors.orange400} />
    </LoadingWrapper>
  )
}

export default BilasolurCard

const SCWrapper = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    background-color: ${colors.grey100};
    grid-column: 1 / -1;
    padding: 1rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: row;
    margin: 0 0 1rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin: 1rem 0 0;
    }
  `}
`

const SCErrorCard = styled.div<{ hasError: boolean }>`
  ${({ hasError, theme: { colors, breakpoints } }) => css`
    padding: 1rem;
    background: ${colors.red100};
    border-radius: 0.5rem;
    margin: 0 0 1rem;
    transition: opacity 320ms ease-in-out;
    opacity: ${hasError ? 1 : 0};

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin: 1rem 0 0;
    }
  `}
`

const SCErrorMessage = styled.div`
  ${({ theme: { colors } }) => css`
    ${Typography.eyebrow};
    color: ${colors.red400};
    line-height: 1.5rem;
  `}
`

const SCImage = styled(Image)`
  border-radius: 0.5rem;
`

const SCCarDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  flex-grow: 2;
  margin-top: 1rem;
`

const SCCarName = styled.p`
  ${({ theme: { colors } }) => css`
    ${Typography.h6};
    color: ${colors.black};
    margin: 0.625rem 0;
  `}
`

const SCCarInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const SCCarInfo = styled.div`
  ${({ theme: { colors } }) => css`
    ${Typography.buttonText};
    margin-right: 1rem;
    color: ${colors.black200};
  `}
`

const SCSellerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  justify-content: flex-end;
`

const SCSellerName = styled.p`
  ${Typography.buttonText};
  font-weight: 950;
  margin-bottom: 0.75rem;
`

const SCSellerPhone = styled.p`
  ${({ theme: { colors } }) => css`
    ${Typography.buttonText};
    color: ${colors.blue400};
    margin-top: 0;
  `}
`

const LoadingWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    grid-column: 1 / -1;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem;
    height: 5rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin: 3rem 0 0;
    }
  `}
`

import CountUp from 'react-countup'
import TooltipWrapper from './tooltip/TooltipWrapper'
import { ExtraStyle } from '../../../types/Types'
import styled, { css } from 'styled-components'
import Typography from '../../../styles/typography'
import { ICalculatorData } from '../../../prismicTypes'
import { createContext, useContext, useState } from 'react'
import { overviewCountUp } from '../../../data/Constants'
interface IProps {
  label: string
  value: number
  suffix: string
  calculatorData: ICalculatorData
  hasTooltip?: boolean
  decimalScale?: number
  extraStyle?: ExtraStyle
}

const TooltipContext = createContext<{ isOpen: boolean; toggleTooltip: () => void }>({
  isOpen: false,
  toggleTooltip: () => undefined,
})
export const useTooltipContext = () => useContext(TooltipContext)

const OverviewItem: React.FC<IProps> = ({
  label,
  value,
  suffix,
  hasTooltip,
  decimalScale = 0,
  extraStyle,
  calculatorData,
}) => {
  //Error handling with 0 months, which was ruining the monthly payments.
  if (value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY) {
    value = 1
  }

  const [toolTipOpen, setToolTipOpen] = useState(false)

  return (
    <TooltipContext.Provider value={{ isOpen: toolTipOpen, toggleTooltip: () => setToolTipOpen(!toolTipOpen) }}>
      <SCOverviewItemWrapper extraStyle={extraStyle}>
        <SCOverviewItem hasToolTip={hasTooltip} onClick={() => setToolTipOpen(!toolTipOpen)}>
          <SCHeaderLabel>{label}</SCHeaderLabel>
          {hasTooltip && <TooltipWrapper calculatorData={calculatorData} />}
        </SCOverviewItem>
        <SCCountUp
          decimal={overviewCountUp.DECIMAL}
          decimals={decimalScale}
          delay={overviewCountUp.DELAY}
          duration={overviewCountUp.DURATION}
          end={value}
          preserveValue
          separator={overviewCountUp.SEPARATOR}
          suffix={suffix}
        />
      </SCOverviewItemWrapper>
    </TooltipContext.Provider>
  )
}

export default OverviewItem

const SCOverviewItemWrapper = styled.div<{ extraStyle?: ExtraStyle }>`
  ${({ extraStyle, theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 0.5rem;
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-bottom: 1.5rem;
    }
    ${extraStyle};
  `}
`

const SCOverviewItem = styled.div<{ hasToolTip?: boolean }>`
  ${({ hasToolTip, theme: { colors } }) => css`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;

    > p {
      transition: all 320ms ease-in-out;
      border-bottom: 0.125rem solid ${colors.white};
    }

    cursor: ${hasToolTip ? 'pointer' : 'default'};
    ${hasToolTip &&
    css`
      &:hover {
        > p {
          border-bottom: 0.125rem solid ${colors.blue200};
        }
      }
    `};
  `}
`

const SCHeaderLabel = styled.p`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.headerLabel}
    margin: 0;

    @media screen and (max-width: ${breakpoints.mobileMax}) {
      font-size: 0.75rem;
    }
  `}
`

const SCCountUp = styled(CountUp)`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h3};
    white-space: nowrap;
    margin: 0;
    font-size: 1.5rem;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: 2rem;
    }

    @media screen and (min-width: ${breakpoints.tabletMax}) {
      margin-top: 1rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      font-size: 2rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMid}) {
      font-size: 2.5rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMidPlus}) {
      font-size: 2.875rem;
    }
  `}
`

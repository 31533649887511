import Image from 'next/image'
import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'
import { IPrismicImage } from '../../prismicBaseTypes'
import Typography from '../../styles/typography'
import { replaceDoubleHyphen } from '../../utils/helpers'
import PrismicRichText from '../PrismicRichText'

interface IProps {
  title: string
  description: RichTextBlock[]
  image: IPrismicImage
  leftCard: boolean
}

const ListCard: React.FC<IProps> = ({ title, description, image, leftCard }) => {
  return (
    <SCListCard leftCard={leftCard}>
      <SCImageWrapper>
        <Image
          src={image.url}
          width={image.dimensions?.width || 0}
          height={image.dimensions?.height || 0}
          alt={title}
        />
      </SCImageWrapper>
      <SCTitle>{replaceDoubleHyphen(title)}</SCTitle>
      <SCDescriptionWrapper>
        <PrismicRichText richText={description} />
      </SCDescriptionWrapper>
    </SCListCard>
  )
}

export default ListCard

const SCListCard = styled.div<{ leftCard: boolean }>`
  ${({ leftCard, theme: { breakpoints } }) => css`
    grid-column: 1/-1;
    margin: 2.5rem 0;
    li {
      margin-bottom: 1rem;
    }
    a {
      margin-top: 2.75rem;
    }

    @media screen and (min-width: ${breakpoints.tabletMax}) {
      grid-column: ${leftCard ? '1/4' : '4/6'};
      margin: 0;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-column: ${leftCard ? '1/5' : '7/11'};
    }
  `}
`
const SCImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 8rem;
  margin-bottom: 2.5rem;
`
const SCTitle = styled.h4`
  ${Typography.h4}
  grid-column: 1 / -1;
  margin-bottom: 1rem;
`
const SCDescriptionWrapper = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    ${Typography.pBody};
    color: ${colors.black400};
    margin-bottom: 1.5rem;
    & p {
      margin-bottom: 1.5rem;
    }
    & ul {
      padding-left: 1.3125rem; /* ul icon positioning */
      margin-bottom: 2.5rem;
    }
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      & p {
        margin-bottom: 2rem;
      }
      margin-bottom: 2.5rem;
    }
  `}
`

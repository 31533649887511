import Fade from '@mui/material/Fade/Fade'
import React from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { Hit, HitsProvided } from 'react-instantsearch-core'
import styled, { css } from 'styled-components'
import { IHitProps } from '../../../types/Search'
import Typography from '../../../styles/typography'
import { Translations } from '../../../data/Translations'
import SearchOverviewHits from './SearchOverviewHits'
interface IProps extends HitsProvided<IHitProps> {}

const SearchResults = ({ hits }: IProps) => {
  const { searchEngine } = Translations.is

  const pageHits: Hit<IHitProps>[] = []
  const textHits: Hit<IHitProps>[] = []
  hits.forEach((hit) => {
    hit.isFeatured ? pageHits.push(hit) : textHits.push(hit)
  })

  return (
    <Fade in={hits.length > 0}>
      <SCModalContent>
        {hits.length <= 1 ? (
          <SCTitle>
            {hits.length} {searchEngine.result}
          </SCTitle>
        ) : (
          <SCTitle>
            {hits.length} {searchEngine.results}
          </SCTitle>
        )}
        {pageHits.length > 0 && (
          <>
            <SCSmallTitle>{searchEngine.pages}</SCSmallTitle>
            <SItemContainer>
              <SearchOverviewHits hits={pageHits}></SearchOverviewHits>
            </SItemContainer>
          </>
        )}
        {textHits.length > 0 && (
          <>
            <SCSmallTitle>{searchEngine.textResults}</SCSmallTitle>
            <SItemContainer>
              <SearchOverviewHits hits={textHits}></SearchOverviewHits>
            </SItemContainer>
          </>
        )}
      </SCModalContent>
    </Fade>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connectHits(SearchResults)

const SCModalContent = styled.div`
  ${({ theme: { colors, shadow, breakpoints } }) => css`
    box-shadow: ${shadow};
    border-radius: 1.5rem;
    padding: 1rem;
    background-color: ${colors.white};
    @media screen and (min-width: ${breakpoints.desktopMin}) {
    }
  `}
`

const SCTitle = styled.h4`
  ${Typography.h4}
  text-align: center;
  margin: 0;
`
const SCSmallTitle = styled.h5`
  ${Typography.h5}
  font-size: 1.125rem;
  font-weight: 900;
`

const SItemContainer = styled.ul`
  padding-left: 0;
`

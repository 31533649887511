import { theme } from '../../styles/globalStyles'
const IconLeftArrow: React.FC = () => {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11552 0.323724C8.54716 0.755356 8.54716 1.45517 8.11552 1.8868L3.00233 7L8.11552 12.1132C8.54716 12.5448 8.54716 13.2446 8.11552 13.6763C7.68389 14.1079 6.98408 14.1079 6.55244 13.6763L0.657708 7.78154C0.226076 7.34991 0.226076 6.65009 0.657708 6.21846L6.55244 0.323724C6.98408 -0.107908 7.68389 -0.107908 8.11552 0.323724Z"
        fill={theme.colors.blue400}
      />
    </svg>
  )
}

export default IconLeftArrow

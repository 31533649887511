import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import PrismicRichText from '../PrismicRichText'

interface IProps {
  title: string
  description: RichTextBlock[]
}

const TwoColumnInfoCard: React.FC<IProps> = ({ title, description }) => (
  <SCWrapper>
    <SCTitle>{title}</SCTitle>
    <SCDescriptionWrapper>
      <PrismicRichText richText={description} />
    </SCDescriptionWrapper>
  </SCWrapper>
)

export default TwoColumnInfoCard

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-column: span 3;
    }
  `}
`

const SCTitle = styled.h5`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h5}
    grid-column: 1 / -1;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 1rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      font-size: 1.125rem;
    }
  `}
`
const SCDescriptionWrapper = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    ${Typography.pBody};
    color: ${colors.black400};
    margin-bottom: 1rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 4.5rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 6.5rem;
    }
  `}
`

import styled, { css } from 'styled-components'
import { ICTACardSlice } from '../../prismicTypes'
import { isPrismicLinkEmpty } from '../../utils/helpers'
import LinkButton from '../common/LinkButton'
import Slice from '../Slice'

interface IProps {
  slice: ICTACardSlice
}

const CTACard: React.FC<IProps> = ({
  slice: {
    slice_type,
    primary: {
      card_title,
      primary_button_link,
      primary_button_text,
      secondary_button_link,
      secondary_button_text,
      card_image,
    },
  },
}: IProps) => {
  return (
    <Slice sliceType={slice_type}>
      <SCWrapper>
        <SCContent>
          <SCTitle>{card_title}</SCTitle>
          <SCButtonWrapper>
            {!isPrismicLinkEmpty(primary_button_link) && (
              <LinkButton buttonText={primary_button_text} link={primary_button_link} extrastyle={SCButton} />
            )}
            {!isPrismicLinkEmpty(secondary_button_link) && (
              <LinkButton
                buttonText={secondary_button_text}
                link={secondary_button_link}
                secondary
                extrastyle={SCButton}
              />
            )}
          </SCButtonWrapper>
        </SCContent>
        <SCImage imgUrl={card_image.url} />
      </SCWrapper>
    </Slice>
  )
}

export default CTACard

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 5rem;
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 15rem;
    }
  `}
`

const SCTitle = styled.h3`
  ${({ theme: { colors, breakpoints } }) => css`
    font-size: 2rem;
    line-height: 2.5rem;
    font-style: normal;
    font-weight: 900;
    color: ${colors.blue400};
    margin-top: 0;

    @media screen and (min-width: ${breakpoints.mobileMid}) {
      margin-top: 2rem;
    }

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-top: 0;
      font-size: 3.5rem;
      line-height: 4.5rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: 0;
    }
  `}
`

const SCContent = styled.div`
  display: flex;
  flex-direction: column;
`

const SCImage = styled.div<{ imgUrl: string }>`
  ${({ imgUrl, theme: { breakpoints } }) => css`
    height: 16rem;
    width: 100%;
    position: relative;
    background-image: url(${imgUrl});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      height: 22rem;
      width: 75%;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      height: auto;
      width: auto;
      background-size: contain;
    }
  `}
`

const SCButtonWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    @media screen and (min-width: ${breakpoints.mobileMid}) {
      justify-content: flex-start;
      button:first-child {
        margin-right: 1.25rem;
      }
    }
  `}
`

const SCButton = css`
  ${({ theme: { breakpoints } }) => css`
    min-width: calc(50% - 0.5rem);

    @media screen and (min-width: ${breakpoints.mobileMid}) {
      min-width: unset;
    }
  `}
`

import { useEffect } from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { calculatorConstants, calculatorErrors } from '../../data/Constants'
import { Translations } from '../../data/Translations'
import Typography from '../../styles/typography'
import { useDebounce } from '../../utils/helpers'
import FormattedNumber from '../common/FormattedNumber'
const { calculator } = Translations.is

interface IProps {
  name: keyof typeof calculator
  value: number
  suffix?: string
  onChange(newValue: number): void
  min?: number
  max: number
  step: number
  depositPercentage?: number
}

const CalculatorInput: React.FC<IProps> = ({
  name,
  suffix,
  value,
  onChange,
  min = 0,
  max,
  step,
  depositPercentage = 0,
}) => {
  const [error, setError] = useState('')
  /* DebouncedValue is used to correct the user without interupting the user input*/
  const [debouncedValue, setDebounceValue] = useState(0)
  let delayedValue = useDebounce(debouncedValue, calculatorConstants.ERROR_INPUT_DELAY)

  const getCorrectErrorMessage = (minValue = true) => {
    /* This part is the minMAX checker for the Deposit depending on the price.*/
    if (minValue) {
      if (name === 'price') {
        return calculatorErrors.MIN_PRICE_ERROR
      } else if (name === 'deposit') {
        return calculatorErrors.MIN_DEPOSIT_ERROR
      } else {
        return calculatorErrors.MIN_CONTRACT_ERROR
      }
    } else {
      if (name === 'price') {
        return calculatorErrors.MAX_PRICE_ERROR
      } else if (name === 'deposit') {
        return calculatorErrors.MAX_DEPOSIT_ERROR
      } else {
        return calculatorErrors.MAX_CONTRACT_ERROR
      }
    }
  }

  const handleOnFocusOut = () => {
    /*    This is to clear the error message incase the user changes the input
        within allowed range after getting an error  */
    if (value > min && value < max) {
      setError('')
    } else if (value < min || value === 0) {
      setError('')
      onChange(min)
      setError(getCorrectErrorMessage())
    } else if (value > max) {
      setError('')
      onChange(max)
      setError(getCorrectErrorMessage(false))
    }
  }
  /* this function is solely for the "input box" not the slider.
     When the user inputs the an illegal amount, the delay is to allow the user to write 123
     without interupting and setting the amount   */
  const handleInputOnChange = (event: number) => {
    //error handling incase the user is inputting zeros
    onChange(event)
    if (event === 0) {
      setDebounceValue(delayedValue + 1)
    } else {
      setDebounceValue(event)
    }
  }

  useEffect(() => {
    handleOnFocusOut()
  }, [delayedValue])

  return (
    <>
      {error && <SCErrorMessage>{error}</SCErrorMessage>}
      <SCContainer hasError={Boolean(error)}>
        <SCInputLabel htmlFor={`${name}_slider`}>{calculator[name]}</SCInputLabel>
        <SCdepositPercentage name={name}>
          <FormattedNumber
            suffix={suffix}
            value={value}
            onChange={(event) => handleInputOnChange(event)}
            extraStyle={SCFormattedInput}
          />
          {name === 'deposit' && (
            <FormattedNumber
              suffix="%"
              value={Math.floor(depositPercentage)}
              displayType="text"
              extraStyle={SCFormattedInputDepositPercentage}
            />
          )}
        </SCdepositPercentage>
        <SCInput
          tabIndex={-1}
          type="range"
          id={`${name}_slider`}
          name={`${name}_slider`}
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={(event) => onChange(parseInt(event.target.value))}
        />
      </SCContainer>
    </>
  )
}

export default CalculatorInput

const SCErrorMessage = styled.div`
  ${({ theme: { colors } }) => css`
    ${Typography.pSmall}
    color: ${colors.red400};
    margin-left: 0.5rem;
    padding-top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    width: 125%;
  `}
`

const SCdepositPercentage = styled.div<{ name: string }>`
  ${({ name }) => css`
    display: flex;
    input {
      width: ${name === 'deposit' ? '88%' : '100%'};
      margin-right: ${name === 'deposit' ? '0.5rem' : '0'};
    }
  `}
`
const SCContainer = styled.div<{ hasError: boolean }>`
  ${({ hasError, theme: { breakpoints, colors } }) => css`
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid ${hasError ? colors.red400 : colors.grey400};
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 1rem;
    position: relative;
    height: 4.5rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      height: 5.5rem;
    }
    @media screen and (min-width: ${breakpoints.mobileMid}) {
      height: unset;
      padding: 0.7rem;
    }
  `}
`

const SCInputLabel = styled.label`
  ${Typography.inputHeader};
`

const SCSliderThumbs = css`
  ${({ theme: { colors } }) => css`
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px,
      rgba(17, 17, 26, 0.1) 0px 16px 56px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: 0.375rem solid ${colors.white};
    background: ${colors.orange400};
    cursor: pointer;
  `}
`

const SCInput = styled.input`
  border: none;
  margin-top: 0.5rem;
  &[type='range'] {
    position: absolute;
    bottom: -0.75rem;
    left: 0;
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  &[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons. */
  }

  &[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;

    ${SCSliderThumbs};
  }

  /* All the same stuff for Firefox */
  &[type='range']::-moz-range-thumb {
    ${SCSliderThumbs};
  }

  /* All the same stuff for IE */
  &[type='range']::-ms-thumb {
    ${SCSliderThumbs};
  }
`

const SCFormattedInput = css`
  ${({ theme: { breakpoints } }) => css`
    border: none;
    min-width: 8.4375rem;
    background: transparent;
    ${Typography.inputText};
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-top: 0.5rem;
      border: none;
    }
  `}
`
const SCFormattedInputDepositPercentage = css`
  ${({ theme: { breakpoints } }) => css`
    border: none;
    ${Typography.pSmall};
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-top: 0.5rem;
    }
  `}
`

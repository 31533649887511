// Borrowed from https://github.com/adam-hanna/goal-seek/blob/develop/src/index.ts
export type Params = {
  fn: (...inputs: any[]) => number
  fnParams: any[]
  percentTolerance?: number
  customToleranceFn?: (arg0: number) => boolean
  maxIterations: number
  maxStep: number
  goal: number
  independentVariableIdx: number
}

export const IsNanError = TypeError('resulted in NaN')
export const FailedToConvergeError = Error('failed to converge')
export const InvalidInputsError = Error('invalid inputs')

const goalSeek = ({
  fn,
  fnParams,
  percentTolerance,
  customToleranceFn,
  maxIterations,
  maxStep,
  goal,
  independentVariableIdx,
}: Params): number => {
  if (typeof customToleranceFn !== 'function') {
    if (!percentTolerance) {
      throw InvalidInputsError
    }
  }

  let differenceBetweenGuesses: number
  let differencefromGoal: number
  let newGuessDifferenceFromGoal: number
  let oldGuess: number
  let newGuess: number
  let res: number

  const absoluteTolerance = ((percentTolerance || 0) / 100) * goal

  // iterate through the guesses
  for (let i = 0; i < maxIterations; i++) {
    // define the root of the function as the error
    res = fn.apply(null, fnParams)
    differencefromGoal = res - goal
    if (isNaN(differencefromGoal)) {
      throw IsNanError
    }
    // was our initial guess a good one?
    if (typeof customToleranceFn !== 'function') {
      if (differencefromGoal > 0 && differencefromGoal <= Math.abs(absoluteTolerance)) {
        return fnParams[independentVariableIdx]
      }
    } else {
      if (customToleranceFn(res)) return fnParams[independentVariableIdx]
    }

    // set the new guess, correcting for maxStep
    oldGuess = fnParams[independentVariableIdx]
    newGuess = oldGuess + differencefromGoal
    if (Math.abs(newGuess - oldGuess) > maxStep) {
      if (newGuess > oldGuess) {
        newGuess = oldGuess + maxStep
      } else {
        newGuess = oldGuess - maxStep
      }
    }

    fnParams[independentVariableIdx] = newGuess

    // re-run the fn with the new guess
    newGuessDifferenceFromGoal = fn.apply(null, fnParams) - goal
    if (isNaN(newGuessDifferenceFromGoal)) {
      throw IsNanError
    }

    // calculate the error
    differenceBetweenGuesses = (newGuessDifferenceFromGoal - differencefromGoal) / differencefromGoal
    if (differenceBetweenGuesses === 0) {
      differenceBetweenGuesses = 0.0001
    }

    // set the new guess based on the error, correcting for maxStep
    newGuess = oldGuess - differencefromGoal / differenceBetweenGuesses
    if (maxStep && Math.abs(newGuess - oldGuess) > maxStep) {
      if (newGuess > oldGuess) {
        newGuess = oldGuess + maxStep
      } else {
        newGuess = oldGuess - maxStep
      }
    }

    fnParams[independentVariableIdx] = newGuess
  }

  // done with iterations, and we failed to converge
  throw FailedToConvergeError
}

export default goalSeek

import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'

interface IPollCalculatorItems {
  name: string
  id: string
}
interface IProps {
  pollItems: IPollCalculatorItems[]
  defaultItem: string
  onPollChange: (input: string) => void
}

const SelectButtonPoll: React.FC<IProps> = ({ pollItems, defaultItem = pollItems[0].id, onPollChange }) => {
  const [pollItemValueID, setPollItemValue] = useState(defaultItem)
  const handlePollItemSelect = (id: string) => {
    setPollItemValue(id)
    onPollChange(id)
  }
  useEffect(() => {
    handlePollItemSelect(defaultItem)
  }, [defaultItem])
  return (
    <SCLoanTypeWrapper>
      {pollItems.map((item, index) => (
        <SCPollItem
          isSelected={item.id === pollItemValueID ? true : false}
          id={`${item.id}${index}`}
          key={`${item.id}${index}`}
          onClick={() => handlePollItemSelect(item.id)}
        >
          {item.name}
        </SCPollItem>
      ))}
    </SCLoanTypeWrapper>
  )
}
export default SelectButtonPoll

const SCLoanTypeWrapper = styled.div``
const SCPollItem = styled.button<{ isSelected: boolean }>`
  ${({ isSelected, theme: { colors } }) => css`
    ${Typography.buttonText};
    min-width: 8rem;
    padding: 0.75rem 1.5rem;
    height: 2.5rem;
    margin: 0.5rem;
    border-radius: 24px;
    border: 1px solid ${colors.blue200};
    transition: background-color 320ms ease;
    &:hover {
      background-color: ${colors.buttonHover};
    }
    ${isSelected &&
    `border: 2px solid ${colors.blue400};
    background-color: ${colors.buttonHover};`}
  `}
`

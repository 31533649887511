import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { IBreakdownSumValues } from '../../../prismicTypes'
import { selectMonths } from '../../../store/loan'
import { InstallmentRowType } from '../../../types/Types'
import InstallmentHeaderRow from './InstallmentHeaderRow'
import InstallmentRow from './InstallmentRow'
import InstallmentSumRow from './InstallmentSumRow'

interface IProps {
  breakdown: Array<InstallmentRowType>
  sumValues: IBreakdownSumValues
  showHeader?: boolean
  showSumValues?: boolean
  animate?: boolean
}

const InstallmentTable: React.FC<IProps> = ({
  breakdown,
  sumValues,
  showHeader = true,
  showSumValues = false,
  animate = false,
}) => {
  const months = useSelector(selectMonths)

  return (
    <SCTable animate={animate}>
      {showHeader && <InstallmentHeaderRow />}
      {breakdown.map((payment, index) => (
        <InstallmentRow key={index} payment={payment} months={months} />
      ))}
      {showSumValues && <InstallmentSumRow sumValues={sumValues} />}
    </SCTable>
  )
}

export default InstallmentTable

const SCTable = styled.table<{ animate: boolean }>`
  ${({ animate }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    min-width: 40rem;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    ${animate && 'animation: fadeIn 0.3s ease forwards;'}
  `}
`

import { theme } from '../../styles/globalStyles'
export const IconPlusSign: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke={theme.colors.blue400} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8V16" stroke={theme.colors.blue400} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 12H8" stroke={theme.colors.blue400} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const IconMinusSign: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke={theme.colors.blue400} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 12H8" stroke={theme.colors.blue400} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}


export const trackBreakdownAnalytics = (
  priceForAnalytics: number,
  depositForAnalytics: number,
  monthsForAnalytics: number
) => {
  //Only track analytics cookies if the user has approved them
  if (typeof analytics !== 'undefined') {
    analytics.track('Calculator Breakdown Viewed', {
      price: priceForAnalytics,
      deposit: depositForAnalytics,
      months: monthsForAnalytics,
    })
  }
}

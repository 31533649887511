import React from 'react'
import {
  ICTACardSlice,
  IFrontPage,
  IPage,
  IProcessCards,
  IFeaturedPagesSlice,
  ISubPage,
  ICalculatorCard,
  IFAQCards,
  IPageCardsSlice,
  ITwoColumnCardsSlice,
  IThreeColumnIconCardsSlice,
  ITwoColumnInfoCardsSlice,
  IServiceProvidersSlice,
  IImageCardSlice,
  IDepartmentInfoSlice,
  ITextCardSlice,
  IWysiwygSlice,
  IIframeSlice,
  ISearchSlice,
  IThreeColumnNewsCardSlice,
  IContactUsSlice,
} from '../../prismicTypes'
import CTACard from '../CTACard'
import ProcessCards from '../ProcessCards'
import SLTextCard from '../TextCard/SLTextCard'
import SLImagePageCards from '../PageCards/SLImagePageCards'
import ServiceProviders from '../ServiceProviders'
import DepartmentInfo from '../DepartmentInfo'
import CalculatorCard from './CalculatorCard'
import { FaqCards } from './FaqCards'
import FeaturedPages from './FeaturedPages'
import ImageCards from './ImageCards'
import ThreeColumnIconCards from './ThreeColumnIconCards'
import { TwoColumnCards } from './TwoColumnCards'
import TwoColumnInfoCards from './TwoColumnInfoCards'
import Wysiwyg from './Wysiwyg'
import Iframe from '../Iframe'
import SearchOverview from '../Search/SearchOverview'
import ThreeColumnNewsCard from './ThreeColumnNewsCard'
import ContactUsSlice from './ContactUsSlice'

interface ISliceZone {
  slices: (IFrontPage.ISlice | IPage.ISlice | ISubPage.ISlice)[]
}

export const SliceZone: React.FC<ISliceZone> = ({ slices }) => {
  return (
    <div>
      {slices?.map((slice, index) => {
        switch (slice.slice_type) {
          case 'latest_news_articles':
            return <ThreeColumnNewsCard slice={slice as IThreeColumnNewsCardSlice} key={index} />
          case 'calculatorcard':
            return <CalculatorCard sliceType={slice.slice_type} slice={slice.primary as ICalculatorCard} key={index} />
          case 'text_section':
            break
          case 'image_cards':
            return (
              <div id={`image_cards-${index}`} key={`image_cards-${index}`}>
                <ImageCards slice={slice as IImageCardSlice} key={index} />
              </div>
            )
          case 'featured_pages':
            return <FeaturedPages slice={slice as IFeaturedPagesSlice} key={index} />
          case 'processcards':
            return <ProcessCards sliceType={slice.slice_type} slice={slice.primary as IProcessCards} key={index} />
          case 'cta_card':
            return <CTACard slice={slice as ICTACardSlice} key={index} />
          case 'faq_cards':
            return (
              <div id={`faq_cards-${index}`} key={`faq_cards-${index}`}>
                <FaqCards slice={slice as IFAQCards} key={index} sliceAnchor={`faq_cards-${index}`} />
              </div>
            )
          case 'two_column_cards':
            return (
              <div id={`two_column_cards-${index}`} key={`two_column_cards-${index}`}>
                <TwoColumnCards slice={slice as ITwoColumnCardsSlice} key={index} />
              </div>
            )
          case 'three_column_icon_cards':
            return (
              <div id={`three_column_icon_cards-${index}`} key={`three_column_icon_cards-${index}`}>
                <ThreeColumnIconCards slice={slice as IThreeColumnIconCardsSlice} key={index} />
              </div>
            )
          case 'two_column_info_cards':
            return (
              <div id={`two_column_info_cards-${index}`} key={`two_column_info_cards-${index}`}>
                <TwoColumnInfoCards slice={slice as ITwoColumnInfoCardsSlice} key={index} />
              </div>
            )
          case 'text_card':
            return (
              <div id={`text_card-${index}`} key={`text_card-${index}`}>
                <SLTextCard slice={slice as ITextCardSlice} key={index} />
              </div>
            )
          case 'page_cards':
            return <SLImagePageCards slice={slice as IPageCardsSlice} key={index} />
          case 'service_providers':
            return (
              <div id={`service_providers-${index}`} key={`service_providers-${index}`}>
                <ServiceProviders slice={slice as IServiceProvidersSlice} key={index} />
              </div>
            )
          case 'department_info':
            return (
              <div id={`department_info-${index}`} key={`department_info-${index}`}>
                <DepartmentInfo slice={slice as IDepartmentInfoSlice} key={index} />
              </div>
            )
          case 'rich_text':
            return (
              <div id={`rich_text-${index}`} key={`rich_text-${index}`}>
                {' '}
                <Wysiwyg slice={slice as IWysiwygSlice} key={index} />
              </div>
            )
          case 'iframe':
            return <Iframe slice={slice as IIframeSlice} key={index} />
          case 'searchpage':
            return <SearchOverview slice={slice as ISearchSlice} key={index} />
          case 'contactus':
            return <ContactUsSlice slice={slice as IContactUsSlice} key={index} />
          default:
            return null
        }
      })}
    </div>
  )
}

export default SliceZone

import { motion } from 'framer-motion'
import React from 'react'
import { DropdownIndicatorProps, components } from 'react-select'
import styled from 'styled-components'

const ANIMATIONS = {
  open: {
    rotate: 180,
  },
  closed: {
    rotate: 0,
  },
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ isDisabled, isFocused, ...rest }) => {
  const isOpen = rest.selectProps.menuIsOpen
  return (
    <components.DropdownIndicator isFocused={isFocused} isDisabled={isDisabled} {...rest}>
      <DropdownWrapper>
        <motion.svg
          width={26}
          height={14}
          viewBox="0 0 26 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          variants={ANIMATIONS}
          animate={isOpen ? 'open' : 'closed'}
        >
          <g xmlns="http://www.w3.org/2000/svg" id="Group 213">
            <path
              id="Path"
              d="M1 1L13 13L25 1"
              stroke="#456372"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </motion.svg>
      </DropdownWrapper>
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator

const DropdownWrapper = styled.div`
  padding-right: var(--spacer-2xs);
`

import { routingPaths } from '../data/Constants'
import { INewsArticle, IPage, ISubPage } from '../prismicTypes'
export interface IHreflang {
  url: string
}

interface ISEOLinks {
  canonical: string
  hreflangs: IHreflang[]
}

export interface ISEOData {
  metaTitle?: string
  metaDescription?: string
  links: ISEOLinks
  noindex: boolean
  ogTitle?: string
  ogDescription?: string
  ogImage?: string
}

const buildFrontpageSeoLinks = (): ISEOLinks => {
  return { hreflangs: [], canonical: '/' }
}

const buildPageSeoLinks = (currentPage: { uid: string }): ISEOLinks => {
  return { hreflangs: [], canonical: `/${currentPage.uid}` }
}
const buildSubpageSeoLinks = (currentPage: { uid: string; data: { link_to_parent: { uid?: string } } }): ISEOLinks => {
  const { data, uid } = currentPage
  return { hreflangs: [], canonical: `/${data.link_to_parent.uid}/${uid}` }
}

export const getPageSEOData = (page: IPage.IProps): ISEOData => {
  return {
    metaTitle: page.data.meta_title,
    metaDescription: page.data.meta_description,
    links: buildPageSeoLinks(page),
    noindex: page.data.noindex,
    ogImage: page.data.og_image?.url,
    ogTitle: page.data.og_title,
    ogDescription: page.data.og_description,
  }
}

export const getSubPageSEOData = (subpage: ISubPage.IProps): ISEOData => {
  return {
    metaTitle: subpage.data.meta_title,
    metaDescription: subpage.data.meta_description,
    links: buildSubpageSeoLinks({
      ...subpage,
      data: {
        ...subpage.data,
        link_to_parent: 'link_to_parent' in subpage.data ? subpage.data.link_to_parent : { uid: routingPaths.news },
      },
    }), //I reckon we need a better solution to this
    noindex: subpage.data.noindex,
    ogImage: subpage.data.og_image?.url,
    ogTitle: subpage.data.og_title,
    ogDescription: subpage.data.og_description,
  }
}
export const getNewsArticleSEOData = (newsArticlePage: INewsArticle.IProps): ISEOData => {
  return {
    metaTitle: newsArticlePage.data.meta_title,
    metaDescription: newsArticlePage.data.meta_description,
    links: buildSubpageSeoLinks({
      ...newsArticlePage,
      data: {
        ...newsArticlePage.data,
        link_to_parent: { uid: routingPaths.news },
      },
    }),
    noindex: newsArticlePage.data.noindex,
    ogImage: newsArticlePage.data.og_image?.url,
    ogTitle: newsArticlePage.data.title.map(({ text }) => text).join('') ?? '',
    ogDescription: newsArticlePage.data.short_description.map(({ text }) => text).join('') ?? '',
  }
}
export const getFrontPageSEOData = (): ISEOData => {
  return {
    links: buildFrontpageSeoLinks(),
    noindex: false,
  }
}

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ISearchSlice } from '../../../prismicTypes'
import Slice from '../../Slice'
import { SearchState } from 'react-instantsearch-core'
import { useRouter } from 'next/router'
import { InstantSearch, SearchBox } from 'react-instantsearch-dom'
import { algoliaIndexName } from '../../../utils/algoliaHelpers'
import { replaceDoubleHyphen, searchClient } from '../../../utils/helpers'
import Typography from '../../../styles/typography'
import SearchResults from './SearchResults'
interface IProps {
  slice: ISearchSlice
}

const SearchOverview: React.FC<IProps> = ({ slice: { slice_type, primary } }) => {
  //get the query from URL
  const router = useRouter()
  const [searchState, setSearchState] = useState<SearchState>(router.query)
  const queryLength = searchState?.query?.length ?? 0
  return (
    <Slice sliceType={slice_type}>
      <SCWrapper>
        <InstantSearch
          onSearchStateChange={(state) => setSearchState(state)}
          indexName={algoliaIndexName}
          searchClient={searchClient}
          searchState={searchState}
        >
          <SCContent>
            <SBackground>
              <SCSearchOverviewTitle>{replaceDoubleHyphen(primary.search_title[0].text)}</SCSearchOverviewTitle>
              <SearchBox
                autoFocus
                translations={{
                  placeholder: primary.search_placeholder[0].text,
                }}
              />
            </SBackground>
            {queryLength === 0 && <></>}
            {queryLength > 0 && (
              <>
                <SearchResults />
              </>
            )}
          </SCContent>
        </InstantSearch>
      </SCWrapper>
    </Slice>
  )
}
export default SearchOverview

const SCWrapper = styled.div`
  grid-column: 1/-1;
  width: calc(100%);
  height: calc(100%);
  border: none;
  border-radius: 0.75rem;
`
const SCSearchOverviewTitle = styled.h3`
  ${Typography.h3};
`
const SCContent = styled.div``
const SBackground = styled.div`
  ${({ theme: { colors, breakpoints, shadow } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 22rem;
    width: 100%;
    border-radius:1rem;
    background-color: ${colors.grey200};
    .ais-SearchBox {
      width: 85%;
      max-width: 46rem;
      height: 4rem;
      margin-top: 4rem;

      @media (min-width: ${breakpoints.tabletMin}) {
        width: 50%;
      }
    }

    .ais-SearchBox-form {
      display: flex;
      position: relative;
    }

    .ais-SearchBox-input {
      width: 100%;
      height: 4rem;
      border: none;
      border-radius: 0.75rem;
      padding-left: 1.25rem;
      color: ${colors.blue400};
      caret-color: ${colors.blue400};
      outline: none;
      // So we can set height on input[type='search'] for Safari
      // @see https://stackoverflow.com/a/7134534
      -webkit-appearance: none;
    }

    .ais-SearchBox-submit {
      position: absolute;
      height: 3rem;
      width: 4rem;
      top: 0;
      padding: 0.75rem 1rem;
      border-radius: 0.75rem;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
      background-color: ${colors.orange400};
      box-shadow: none;
      transition: transform 320ms ease, box-shadow 320ms ease;

      @media (min-width: ${breakpoints.tabletMin}) {
        &:hover,
        &:focus {
          box-shadow: ${shadow};
          transform: translate3d(0, calc(-52%), 0);
        }
      }

      svg {
        height: 1rem;
        width: 1rem;
        fill: white;
      }
      outline: none;
      &.focus-visible {
        border-radius: 1rem;
        box-shadow: ${shadow} 
        transition: box-shadow 320ms ease;
      }
      .focus-visible {
        border-radius: 0.75rem;
      }
    }
    .ais-SearchBox-reset {
      display: none;
    }
    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  `}
`

import Image from 'next/image'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Backdrop, Dialog, Fade, useMediaQuery } from '@mui/material'
import { styled as materialStyled } from '@mui/material/styles'

import HeaderInfo from './HeaderInfo'
import InstallmentTable from './InstallmentTable'
import { selectDeposit, selectMonths, selectPrice } from '../../../store/loan'
import { getLoanPaymentSumValues, getPaymentSchedule } from '../../../utils/utils'
import { Translations } from '../../../data/Translations'
import styled, { css } from 'styled-components'
import Typography from '../../../styles/typography'
import { ICalculatorData } from '../../../prismicTypes'
import { mediaQueries } from '../../../data/Constants'
import IconLeftArrow from '../../../public/svg/IconLeftArrow'
import { trackBreakdownAnalytics } from '../../../utils/analytics'

interface IProps {
  isOpen: boolean
  handleClose(): void
  calculatorData: ICalculatorData
  baseLoanData: ICalculatorData
}

const BreakdownModal: React.FC<IProps> = ({ isOpen, handleClose, calculatorData, baseLoanData }) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const isTabletOrSmaller = useMediaQuery(mediaQueries.isTabletOrSmaller)
  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)
  const months = useSelector(selectMonths)
  trackBreakdownAnalytics(price, deposit, months)
  const schedule = getPaymentSchedule(price, deposit, months, calculatorData)
  const sumValues = getLoanPaymentSumValues(schedule)
  const { breakdown, common } = Translations.is

  const first3Rows = schedule.slice(0, 3)
  const last3Rows = schedule.slice(Math.max(schedule.length - 3, 0))

  const onClose = () => {
    handleClose()
    setTimeout(() => setShowMore(false), 500)
  }
  return (
    <SCDialog
      fullScreen={isTabletOrSmaller}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      scroll="body"
      fullWidth
      maxWidth="lg"
    >
      <Fade in={isOpen}>
        <SCModalContent>
          <SCHeaderContainer>
            {isTabletOrSmaller ? (
              <SCTabletHeader onClick={onClose}>
                <IconLeftArrow />
                <SCTabletHeaderText>{breakdown.yourLoan}</SCTabletHeaderText>
              </SCTabletHeader>
            ) : (
              <SCCloseButton onClick={onClose}>
                <Image src="/svg/x_filled.svg" width="40" height="40" alt="CloseButton" />
              </SCCloseButton>
            )}
          </SCHeaderContainer>
          <SCSmallTitle>{breakdown.breakdown}</SCSmallTitle>
          <HeaderInfo calculatorData={calculatorData} baseLoanData={baseLoanData} />
          <SCSmallTitle>{breakdown.paymentSchedule}</SCSmallTitle>
          <SCBreakdownTable>
            {showMore ? (
              <InstallmentTable breakdown={schedule} sumValues={sumValues} showSumValues animate />
            ) : (
              <>
                <InstallmentTable breakdown={first3Rows} sumValues={sumValues} />
                <SCSHowMoreButton onClick={() => setShowMore(true)}>
                  <Image src="/svg/down_arrow_filled.svg" width={32} height={32} alt="ShowMoreButton" />
                  <SCSmallParagraph>{breakdown.showAllPayments}</SCSmallParagraph>
                </SCSHowMoreButton>
                <InstallmentTable showHeader={false} breakdown={last3Rows} sumValues={sumValues} showSumValues />
              </>
            )}
          </SCBreakdownTable>
          {showMore && (
            <SCBackButton onClick={onClose}>
              <Image src="/svg/left_arrow_blue.svg" width={24} height={24} alt="BackButton" />
              <SCButtonText>{common.back}</SCButtonText>
            </SCBackButton>
          )}
        </SCModalContent>
      </Fade>
    </SCDialog>
  )
}

export default BreakdownModal

const SCDialog = materialStyled(Dialog)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SCModalContent = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: 0.75rem;
    padding: 4rem 6.5rem;
    width: 100%;
    margin-bottom: 3.5rem;
    padding: 1.0625rem;

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      padding: 4rem 6.5rem;
    }
  `}
`
const SCBreakdownTable = styled.div`
  ${({ theme: { breakpoints, shadow, colors } }) => css`
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0.3125rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.625rem;
      background-color: ${colors.grey400};
      -webkit-box-shadow: ${shadow};
    }
    @media screen and (max-width: ${breakpoints.tabletMid}) {
      overflow-x: scroll;
      display: flex;
      flex-direction: column;
    }
  `}
`

const SCSmallTitle = styled.h5`
  ${Typography.h5}
  font-size: 1.125rem;
  font-weight: 900;
`

const SCSmallParagraph = styled.span`
  ${Typography.pSmall}
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 2rem;
`

const SCCloseButton = styled.button`
  ${({ theme: { colors } }) => css`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.75rem;
    color: ${colors.blue400};
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  `}
`
const SCTabletHeader = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.25rem 0;
    margin: 0 -0.8125rem;
    @media screen and (max-width: ${breakpoints.tabletMid}) {
      padding: 1.25rem;
    }
  `}
`
const SCTabletHeaderText = styled.h5`
  ${Typography.h5}
  font-size: 1.125rem;
  width: 100%;
  text-align: center;
  margin: 0;
`
const SCHeaderContainer = styled.div`
  display: flex;
  grid-column: 2;
`

const SCSHowMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 11.25rem;
  margin: 1.5rem 0;
  > span {
    font-weight: bold;
  }
`

const SCBackButton = styled.button`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.875rem;
  > span {
    margin-left: 0.75rem;
  }
`

const SCButtonText = styled.span`
  ${Typography.buttonText}
`

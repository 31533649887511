import styled, { css } from 'styled-components'

import { IServiceProvidersSlice } from '../../prismicTypes'
import Typography from '../../styles/typography'
import { Translations } from '../../data/Translations'
import PrismicRichText from '../PrismicRichText'
import Slice from '../Slice'

interface IProps {
  slice: IServiceProvidersSlice
}

const ServiceProviders: React.FC<IProps> = ({
  slice: {
    slice_type,
    items,
    primary: { title },
  },
}: IProps) => {
  const translations = Translations.is

  return (
    <Slice sliceType={slice_type} gridStyle={SCGrid}>
      <SCWrapper>
        <SCTitle>{title}</SCTitle>
        <SCTable>
          <SCRow>
            <SCHeadElement>{translations.serviceProviders.type}</SCHeadElement>
            <SCHeadElement>{translations.serviceProviders.provider}</SCHeadElement>
            <SCHeadElement>{translations.serviceProviders.address}</SCHeadElement>
            <SCHeadElement>{translations.serviceProviders.phone}</SCHeadElement>
          </SCRow>
          {items.map(({ car_type, provider, address, phone }) => {
            return (
              <SCRow>
                <SCElement>{car_type}</SCElement>
                <SCElement>
                  <PrismicRichText extraLinkStyle={Typography.h6} richText={provider} />
                </SCElement>
                <SCElement>{address}</SCElement>
                <SCElement>{phone}</SCElement>
              </SCRow>
            )
          })}
        </SCTable>
      </SCWrapper>
    </Slice>
  )
}

export default ServiceProviders

const SCGrid = css`
  ${({ theme: { breakpoints } }) => css`
    margin: 5rem 0;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 10rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 15rem;
    }
  `}
`

const SCTitle = styled.h1`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h1}
    margin-bottom: 2.5rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 5rem;
    }
  `}
`

const SCWrapper = styled.div`
  grid-column: 1 / -1;
`

const SCTable = styled.table`
  ${({ theme: { colors, breakpoints } }) => css`
    width: fit-content;
    border-collapse: collapse;
    overflow-x: scroll;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      width: 100%;
    }
    & > tr:not(:last-child) {
      & > td {
        border-bottom: 0.0625rem solid ${colors.grey200};
      }
    }
  `}
`

const SCRow = styled.tr``

const SCHeadElement = styled.th`
  ${({ theme: { colors } }) =>
    css`
      ${Typography.h6}
      text-align: left;
      padding: 1.5rem 0;
      border-bottom: 0.0625rem solid ${colors.grey200};
    `}
`

const SCElement = styled.td`
  ${Typography.h6}
  min-width: 12rem;
  padding: 0.875rem 0;
`

import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Translations } from '../../../data/Translations'
import { IBreakdownSumValues } from '../../../prismicTypes'
import { selectMonths } from '../../../store/loan'
import FormattedNumber from '../../common/FormattedNumber'
interface IProps {
  sumValues: IBreakdownSumValues
}
const InstallmentSumRow: React.FC<IProps> = ({ sumValues }) => {
  /*
   * Explanatory information on why we are adding + 2..
   * When months are selected as 84, selectMonths is 83 (array starts on 0).
   * Since we don't want to show the first payment date (gjalddagi) as 0, we add 1 so the "table" starts on 1,2....84.
   * So 83 + 2 means line 85, because line 84 is the last row in the table.
   */
  const months = useSelector(selectMonths) + 2
  const { breakdown } = Translations.is

  return (
    <>
      <SCLastSumTableCell style={{ order: months }}>{breakdown.totalRow}</SCLastSumTableCell>
      <SCLastSumTableCell style={{ order: months }}></SCLastSumTableCell>
      <SCLastSumTableCell style={{ order: months }}>
        <FormattedNumber value={sumValues.installment} suffix="" displayType="text" />
      </SCLastSumTableCell>
      <SCLastSumTableCell style={{ order: months }}>
        <FormattedNumber value={sumValues.interest} suffix="" displayType="text" />
      </SCLastSumTableCell>
      <SCLastSumTableCell style={{ order: months }}>
        <FormattedNumber value={sumValues.cost} suffix="" displayType="text" />
      </SCLastSumTableCell>
      <SCLastSumTableCell style={{ order: months }}>
        <FormattedNumber value={sumValues.totalPaid} suffix="" displayType="text" />
      </SCLastSumTableCell>
      <SCLastSumTableCell isLastColumn style={{ order: months }}></SCLastSumTableCell>
    </>
  )
}

export default InstallmentSumRow

const SCLastSumTableCell = styled.div<{ isLastColumn?: boolean }>`
  ${({ isLastColumn, theme: { colors } }) => css`
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden; // Or flex might break
    width: 14.28%; // 7 columns, each cell 1/7 width
    color: ${colors.blue400};
    font-family: 'AreaNormal', sans-serif;
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    padding: 0.5rem;
    margin-top: 1.5rem;
    ${isLastColumn && `text-align: right;`}
  `}
`

import { useSelector } from 'react-redux'

import { useMediaQuery } from '@mui/material'

import OverviewButton from './OverviewButton'
import OverviewItem from './OverviewItem'
import { calculatorConstants, mediaQueries } from '../../../data/Constants'
import { Translations } from '../../../data/Translations'
import { selectDeposit, selectMonths, selectPrice } from '../../../store/loan'
import { getCalculatedLoan, getCalculatorDataFromLoanType } from '../../../utils/utils'
import styled, { css } from 'styled-components'
import { ICalculatorData } from '../../../prismicTypes'

interface IProps {
  calculator_data: ICalculatorData[]
  loan_type: number
  loan_subType: number
  loanPercentage: number
}

const Overview: React.FC<IProps> = ({ calculator_data, loan_type, loan_subType, loanPercentage }) => {
  const { calculator } = Translations.is
  const isTabletOrSmaller = useMediaQuery(mediaQueries.isTabletOrSmaller)

  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)
  const months = useSelector(selectMonths)
  const calculatorData = getCalculatorDataFromLoanType(calculator_data, loan_type, loan_subType)
  const calculatedLoan = getCalculatedLoan(price, deposit, months, calculatorData)

  return (
    <SCContainer>
      <SCOverviewRow>
        <OverviewItem
          label={calculator.monthlyPayment}
          value={calculatedLoan.monthlyPayment}
          suffix={calculatorConstants.CURRENCY_SUFFIX}
          extraStyle={SCLeftItem}
          calculatorData={calculatorData}
        />
        <OverviewItem
          label={calculator.interestRate}
          value={calculatedLoan.interestRate}
          suffix="%"
          hasTooltip
          decimalScale={2}
          extraStyle={SCRightItem}
          calculatorData={calculatorData}
        />
      </SCOverviewRow>
      <SCOverviewRow>
        <OverviewItem
          label={calculator.loanPrincipal}
          value={calculatedLoan.principal}
          suffix={calculatorConstants.CURRENCY_SUFFIX}
          extraStyle={SCLeftItem}
          calculatorData={calculatorData}
        />
        <OverviewItem
          label={calculator.loanPercentage}
          value={loanPercentage}
          suffix="%"
          decimalScale={0}
          extraStyle={SCLastItem}
          calculatorData={calculatorData}
        />
      </SCOverviewRow>
      {!isTabletOrSmaller && <OverviewButton />}
    </SCContainer>
  )
}

export default Overview

const SCContainer = styled.div`
  ${({ theme: { breakpoints } }) => css`
    padding: 0;
    min-width: unset;
    width: 50%;
    margin-top: 2rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-top: 0;
    }
  `}
`

const SCOverviewRow = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: ${breakpoints.tabletMin}) and (max-width: ${breakpoints.tabletMax}) {
      flex-direction: column;
    }
  `}
`

const SCLeftItem = css`
  ${({ theme: { breakpoints } }) => css`
    margin-right: 1rem;
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      min-width: 15rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMid}) {
      min-width: 21rem;
    }
    @media screen and (max-width: ${breakpoints.mobileMax}) {
      min-width: 11rem;
    }
  `}
`

const SCRightItem = css`
  ${({ theme: { breakpoints } }) => css`
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      min-width: 11rem;
    }
    @media screen and (width: ${breakpoints.mobileMax}) {
      padding-left: 5rem;
    }
  `}
`

const SCLastItem = css`
  ${({ theme: { breakpoints } }) => css`
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      min-width: 11rem;
    }
    @media screen and (min-width: ${breakpoints.tabletMin}) and (max-width: ${breakpoints.tabletMax}) {
      margin-bottom: 0;
    }
    @media screen and (width: ${breakpoints.mobileMax}) {
      padding-left: 5rem;
    }
  `}
`

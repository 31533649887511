import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TooltipButton from './TooltipButton'
import { Translations } from '../../../../data/Translations'
import { selectDeposit, selectPrice, updateDeposit } from '../../../../store/loan'
import { getInterestRate } from '../../../../utils/utils'
import styled, { css } from 'styled-components'
import Typography from '../../../../styles/typography'
import { ICalculatorData, IInterrestRates } from '../../../../prismicTypes'
import { calculatorConstants } from '../../../../data/Constants'
import { useTooltipContext } from '../OverviewItem'

interface DepositDatum {
  deposit: number
  interestRate: number
}

const getDepositData = (price: number, interest_rates: IInterrestRates[]): Array<DepositDatum> => {
  const depositArray: Array<DepositDatum> = interest_rates
    .map((data) => {
      let deposit = price - price * (data.financingRatioTo / 100)
      if (deposit < price * calculatorConstants.MIN_DEPOSIT_PERCENTAGE) {
        deposit = price * calculatorConstants.MIN_DEPOSIT_PERCENTAGE
      }
      if (deposit < calculatorConstants.MIN_DEPOSIT_AMOUNT) {
        deposit = calculatorConstants.MIN_DEPOSIT_AMOUNT
      }
      return {
        deposit,
        interestRate: data.percentage,
      }
    })
    .sort((a, b) => (a.deposit < b.deposit || b.interestRate < a.interestRate ? 1 : -1))
  //Remove duplicate deposit from array.
  const depositSize = depositArray.length - 1
  if (depositArray[depositSize].deposit === depositArray[depositSize - 1].deposit) {
    depositArray.splice(-1, 1)
  }

  return depositArray
}
interface IProps {
  calculatorData: ICalculatorData
}

const OverviewTooltip: React.FC<IProps> = ({ calculatorData }) => {
  const dispatch = useDispatch()
  const { calculator } = Translations.is
  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)
  const [selectedRate, setSelectedRate] = useState(getInterestRate(price, deposit, calculatorData.loan_data.interest))
  const [isMounted, setIsMounted] = useState(false)

  const depositData = getDepositData(price, calculatorData.loan_data.interest)

  const handleSelectDeposit = (depositDatum: DepositDatum) => {
    setSelectedRate(depositDatum.interestRate)
    dispatch(updateDeposit(depositDatum.deposit))
  }

  const { isOpen, toggleTooltip } = useTooltipContext()

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return isOpen ? (
    <SCTooltipContainer isMounted={isMounted}>
      <SCHeadingRow>
        <SCEyeBrow>{calculator.deposit}</SCEyeBrow>
        <SCButton tabIndex={isOpen ? 0 : -1} onClick={() => toggleTooltip()}>
          <Image src="/svg/x_small.svg" width="10" height="10" alt="Exit-Tooltip" />
        </SCButton>
      </SCHeadingRow>
      {depositData.map((datum, index) => (
        <TooltipButton
          isVisible={isOpen}
          key={index}
          deposit={datum.deposit}
          interestRate={datum.interestRate}
          selected={selectedRate === datum.interestRate}
          handleOnClick={() => handleSelectDeposit(datum)}
        />
      ))}
      <SCArrowContainer>
        <SCArrow />
      </SCArrowContainer>
    </SCTooltipContainer>
  ) : null
}

export default OverviewTooltip

const SCTooltipContainer = styled.div<{ isMounted: boolean }>`
  ${({ isMounted, theme: { colors, shadow } }) => css`
    position: absolute;
    width: 20rem;
    bottom: 1.5rem;
    background-color: ${colors.white};
    border-radius: 0.75rem;
    transform: translate(-50%, -0.75rem);
    box-shadow: ${shadow};
    padding: 1rem;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    ${isMounted && 'animation: fadeIn 0.3s ease forwards;'}
  `}
`

const SCEyeBrow = styled.span`
  ${Typography.eyebrow}
`

const SCHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SCButton = styled.button`
  width: 2rem;
  height: 2rem;
  > div {
    padding-left: 0.125rem;
    padding-bottom: 0.125rem;
  }
`

const SCArrowContainer = styled.div`
  transform: translate(-50%, 0.6875rem);
  position: absolute;
  left: 50%;
`

const SCArrow = styled.div`
  ${({ theme: { colors } }) => css`
    width: 0.625rem;
    height: 0.625rem;
    transform: rotate(45deg);
    background-color: ${colors.white};
  `}
`

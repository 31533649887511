import { ITextCardSlice } from '../../prismicTypes'
import Slice from '../Slice'
import { TextCard } from './TextCard'
import styled, { css } from 'styled-components'

interface IProps {
  slice: ITextCardSlice
}

const SLTextCard: React.FC<IProps> = ({
  slice: {
    slice_type,
    primary: { title, description },
  },
}) => {
  return (
    <Slice sliceType={slice_type}>
      <SCWrapper>
        <TextCard title={title} description={description} />
      </SCWrapper>
    </Slice>
  )
}

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: span 8;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 9rem;
    }
  `}
`

export default SLTextCard

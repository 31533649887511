import React from 'react'
import styled, { css } from 'styled-components'

import { IThreeColumnIconCardsSlice } from '../../prismicTypes'
import ThreeColumnIconCard from '../ThreeColumnIconCard'
import Typography from '../../styles/typography'
import { replaceDoubleHyphen } from '../../utils/helpers'
import Slice from '../Slice'

interface IProps {
  slice: IThreeColumnIconCardsSlice
}

const ThreeColumnIconCards: React.FC<IProps> = ({
  slice: {
    slice_type,
    primary: { three_column_title },
    items,
  },
}) => {
  return (
    <Slice sliceType={slice_type} gridStyle={SCGridStyle}>
      {three_column_title && <SCTitle>{replaceDoubleHyphen(three_column_title)}</SCTitle>}
      <SCCardGridContainer>
        {items.map((card) => {
          return (
            <ThreeColumnIconCard
              key={card.three_column_card_title}
              title={card.three_column_card_title}
              description={card.three_column_card_description}
              icon={card.three_column_card_icon && card.three_column_card_icon}
            />
          )
        })}
      </SCCardGridContainer>
    </Slice>
  )
}

export default ThreeColumnIconCards

const SCGridStyle = css`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 56px;
    @media screen and (min-width: ${breakpoints.desktopXSmall}) {
      margin-bottom: 104px;
    }
  `}
`

const SCTitle = styled.h3`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h3}
    grid-column: 1 / -1;
    margin: 56px 0 40px 0;
    max-width: 100%;
    @media screen and (min-width: ${breakpoints.tablet}) {
      width: 100%;
    }
    @media screen and (min-width: ${breakpoints.desktopXSmall}) {
      margin: 104px 0 40px 0;
    }
    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      width: 1006px;
      margin: 104px 0 56px 0;
    }
    @media screen and (min-width: ${breakpoints.desktopMd}) {
      margin: 104px 0 80px 0;
    }
    @media screen and (min-width: ${breakpoints.desktopLarge}) {
      margin: 104px 0;
    }
  `}
`

const SCCardGridContainer = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 40px;
    }
    @media screen and (min-width: ${breakpoints.desktopXSmall}) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px;
    }
    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      grid-gap: 56px;
    }
    @media screen and (min-width: ${breakpoints.desktopMd}) {
      grid-gap: 80px;
    }
    @media screen and (min-width: ${breakpoints.desktopLarge}) {
      grid-gap: 104px;
    }
  `}
`

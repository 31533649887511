import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IIframeSlice } from '../../prismicTypes'
import Slice from '../Slice'
import { theme } from '../../styles/globalStyles'
import Bubbles from '../Bubbles'

interface IProps {
  slice: IIframeSlice
}

const Iframe: React.FC<IProps> = ({ slice: { slice_type, primary } }) => {
  const { iframe_url } = primary
  const [iframeHasLoaded, setIframeHasLoaded] = useState(false)

  useEffect(() => {}, [iframeHasLoaded])

  return (
    <>
      {!iframeHasLoaded && (
        <SCLoadingWrapper>
          <Bubbles size="large" color={theme.colors.orange400} />
        </SCLoadingWrapper>
      )}
      <Slice sliceType={slice_type}>
        <SCIframeWrapper>
          <iframe src={iframe_url} onLoad={() => setIframeHasLoaded(true)} />
        </SCIframeWrapper>
      </Slice>
    </>
  )
}

export default Iframe

const SCLoadingWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    z-index: 50;
    position: absolute;
    top: 6.5rem;
    left: 0;
    height: 100vh;
    width: 100vw;
  `}
`

const SCIframeWrapper = styled.div`
  ${({ theme: { shadow } }) => css`
    grid-column: 1/-1;
    height: 202vh;

    iframe {
      width: calc(100%);
      border: none;
      height: 100%;
      box-shadow: ${shadow};
      border-radius: 0.75rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  `}
`

import React from 'react'
import { css } from 'styled-components'
import { ITwoColumnCardsSlice } from '../../prismicTypes'
import ListCard from '../ListCard.tsx'
import Slice from '../Slice'

interface IProps {
  slice: ITwoColumnCardsSlice
}

export const TwoColumnCards: React.FC<IProps> = ({ slice: { slice_type, items } }) => {
  return (
    <Slice sliceType={slice_type} gridStyle={SCGridStyle}>
      {items.map((card, index) => {
        return (
          <ListCard
            key={card.list_card_title}
            title={card.list_card_title}
            description={card.list_card_description}
            image={card.list_card_image}
            leftCard={index % 2 === 0}
          />
        )
      })}
    </Slice>
  )
}

const SCGridStyle = css`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 4rem;

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 8rem;
    }
  `}
`

import React from 'react'
import styled, { css } from 'styled-components'

import PrismicRichText from '../PrismicRichText'
import { IWysiwygSlice } from '../../prismicTypes'
import Slice from '../Slice'
import Typography from '../../styles/typography'

interface IProps {
  slice: IWysiwygSlice
}

const Wysiwyg: React.FC<IProps> = ({ slice: { primary } }) => {
  const { text } = primary
  return (
    <Slice>
      <SWrapper>
        <PrismicRichText richText={text} />
      </SWrapper>
    </Slice>
  )
}

export default Wysiwyg

const SWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1/-1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 3rem;
    }
    h1 {
      ${Typography.h1}
    }
    h2 {
      ${Typography.h2}
    }
    h3 {
      ${Typography.h3}
    }
    h4 {
      ${Typography.h4}
    }
    h5 {
      ${Typography.h5}
    }
    h6 {
      ${Typography.h6}
    }

    li {
      margin-bottom: 1rem;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 4rem;
    }
    p {
      padding-bottom: 32px;
    }
  `}
`

import React from 'react'
import ImageSection from '../imageSection/ImageSection'
import { IImageCardSlice } from '../../prismicTypes'
import LinkButton from '../common/LinkButton'
import { css } from 'styled-components'
import { isPrismicLinkEmpty } from '../../utils/helpers'
import { Translations } from '../../data/Translations'

interface IProps {
  slice: IImageCardSlice
}
const ImageCards: React.FC<IProps> = ({
  slice: {
    slice_type,
    primary: {
      image_card_label,
      image_card_title,
      image_card_description,
      image_card_list_columns,
      image_card_image,
      align_image_right,
      image_card_url,
      image_card_link_text,
    },
  },
}) => {
  return (
    <ImageSection
      sliceType={slice_type}
      label={image_card_label}
      title={image_card_title}
      description={image_card_description}
      listColumnCount={image_card_list_columns}
      image={image_card_image}
      alignImageRight={align_image_right}
      useH1
    >
      <>
        {!isPrismicLinkEmpty(image_card_url) && (
          <LinkButton
            buttonText={image_card_link_text || Translations.is.common.checkItOut}
            link={image_card_url}
            secondary
            extrastyle={SCExtraStyle}
          />
        )}
      </>
    </ImageSection>
  )
}

export default ImageCards

const SCExtraStyle = css`
  ${({ theme: { colors } }) => css`
    span {
      color: ${colors.blue400};
      :hover {
        color: ${colors.black400};
      }
    }
  `}
`

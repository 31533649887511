import styled, { css } from 'styled-components'
import { InstallmentRowType } from '../../../types/Types'
import FormattedNumber from '../../common/FormattedNumber'
import { SCHeaderTableCell } from './InstallmentHeaderRow'

interface IProps {
  payment: InstallmentRowType
  months: number
}

const InstallmentRow: React.FC<IProps> = ({ payment, months }) => {
  const order = 1 + payment.month
  const isLastRow = payment.month === months

  return (
    <>
      <SCTableCell isLastRow={isLastRow} style={{ order }}>
        {payment.month}
      </SCTableCell>
      <SCTableCell isLastRow={isLastRow} style={{ order }}>
        {payment.paymentDate}
      </SCTableCell>
      <SCTableCell isLastRow={isLastRow} style={{ order }}>
        <FormattedNumber value={payment.installment} suffix="" displayType="text" />
      </SCTableCell>
      <SCTableCell isLastRow={isLastRow} style={{ order }}>
        <FormattedNumber value={payment.interest} suffix="" displayType="text" />
      </SCTableCell>
      <SCTableCell isLastRow={isLastRow} style={{ order }}>
        <FormattedNumber value={payment.paymentFee} suffix="" displayType="text" />
      </SCTableCell>
      <SCTableCell isLastRow={isLastRow} style={{ order }}>
        <FormattedNumber value={payment.totalPayment} suffix="" displayType="text" />
      </SCTableCell>
      <SCTableCell isLastRow={isLastRow} isLastColumn style={{ order }}>
        <FormattedNumber value={payment.remainder} suffix="" displayType="text" />
      </SCTableCell>
    </>
  )
}

export default InstallmentRow

const SCTableCell = styled(SCHeaderTableCell) <{ isLastRow: boolean }>`
  ${({ isLastRow }) => css`
    ${isLastRow &&
    css`
      border-bottom: 0;
    `}
  `}
`

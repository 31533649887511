import { useMediaQuery } from '@mui/material'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import { mediaQueries } from '../../data/Constants'
import { Translations } from '../../data/Translations'
import { IPageCardsSlice } from '../../prismicTypes'
import Typography from '../../styles/typography'
import { replaceDoubleHyphen } from '../../utils/helpers'
import PrismicInlineLink from '../Links/PrismicInlineLink'
import Slice from '../Slice'
interface IProps {
  slice: IPageCardsSlice
}

const SLImagePageCards: React.FC<IProps> = ({ slice: { slice_type, items } }) => {
  const isMobile = useMediaQuery(mediaQueries.isMobile)
  return (
    <Slice sliceType={slice_type}>
      <SCWrapper>
        {items.map(({ card_title, card_description, link, card_image }) => {
          return (
            <SCCard key={card_title}>
              <SCImageWrapper>
                <Image
                  src={card_image.url}
                  height={isMobile ? 80 : 110}
                  width={isMobile ? 184 : 222}
                  alt={card_title}
                />
              </SCImageWrapper>
              <SCCardTitle>{replaceDoubleHyphen(card_title)}</SCCardTitle>
              <SCDescription>{card_description[0].text}</SCDescription>
              <SCLinkWrapper>
                <PrismicInlineLink link={link}>{Translations.is.common.checkItOut}</PrismicInlineLink>
              </SCLinkWrapper>
            </SCCard>
          )
        })}
      </SCWrapper>
    </Slice>
  )
}

export default SLImagePageCards

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-bottom: 6rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 10rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMid}) {
      grid-template-columns: repeat(3, minmax(33%, 1fr));
      margin-bottom: 18.5rem;
    }
  `}
`

const SCImageWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    align-self: flex-start;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      align-self: center;
    }
  `}
`

const SCCard = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    background-color: ${colors.grey100};
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2rem;
    border-radius: 0.75rem;
    position: relative;
    justify-content: left;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      padding: 3.125rem 3rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMid}) {
      padding: 5.5rem 3rem;
    }
  `}
`

const SCCardTitle = styled.h4`
  ${Typography.h4};
  margin-top: 3.5rem;
  margin-bottom: 1rem;
`

const SCDescription = styled.p`
  ${Typography.pSmall};
  margin-bottom: 2rem;
`

const SCLinkWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      position: absolute;
      bottom: 3rem;
      left: 3rem;
    }
  `}
`

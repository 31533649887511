import { theme } from '../../styles/globalStyles'

const IconInstagram: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill={theme.colors.white} stroke={theme.colors.grey400} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8046 10.8157C15.6257 10.8157 15.4807 10.9607 15.4807 11.1396C15.4807 11.3185 15.6257 11.4636 15.8046 11.4636C15.9836 11.4636 16.1286 11.3185 16.1286 11.1396C16.1286 10.9607 15.9836 10.8157 15.8046 10.8157Z"
        fill={theme.colors.white}
      />
      <path
        d="M15.8046 10.8157C15.6257 10.8157 15.4807 10.9607 15.4807 11.1396C15.4807 11.3185 15.6257 11.4636 15.8046 11.4636C15.9836 11.4636 16.1286 11.3185 16.1286 11.1396C16.1286 10.9607 15.9836 10.8157 15.8046 10.8157V10.8157"
        stroke={theme.colors.blue400}
        strokeWidth="1.94291"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7168 15.0269H16.207V20.534" fill={theme.colors.white} />
      <path
        d="M14.7168 15.0269H16.207V20.534"
        stroke={theme.colors.blue400}
        strokeWidth="1.94291"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7034 20.5434H17.6958H14.7034Z" fill={theme.colors.white} />
      <path
        d="M14.7034 20.5434H17.6958"
        stroke={theme.colors.blue400}
        strokeWidth="1.94291"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconInstagram

import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../styles/globalStyles'

type Size = 'small' | 'large'

interface IProps {
  size?: Size
  color?: string
}

const Bubbles: React.FC<IProps> = ({ size = 'small', color = theme.colors.white }: IProps) => (
  <SWrapper>
    <SBubble size={size} color={color} />
    <SBubble size={size} color={color} />
    <SBubble size={size} color={color} />
  </SWrapper>
)

export default Bubbles

const SBubble = styled.div<{ size: Size; color: string }>`
  ${({ size, color }) => css`
    animation: expand 1000ms ease-in-out infinite;
    border-radius: 50%;
    display: inline-block;
    transform-origin: center center;
    margin: ${size === 'large' ? '0 0.625rem' : '0 0.3125rem'};
    width: ${size === 'large' ? '1.875rem' : '0.9375rem'};
    height: ${size === 'large' ? '1.875rem' : '0.9375rem'};
    background-color: ${color};
    &:nth-child(2) {
      animation-delay: 180ms;
    }
    &:nth-child(3) {
      animation-delay: 360ms;
    }
    @keyframes expand {
      0% {
        transform: scale(1);
      }
      25% {
        transform: scale(1.5);
      }
    }
  `}
`

const SWrapper = styled.div`
  z-index: 1;
`

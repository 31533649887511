import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import CalculatorInput from './CalculatorInput'
import BilasolurCard from '../BilasolurCard'
import BreakdownModal from './breakdown/BreakdownModal'
import OverviewButton from './overview/OverviewButton'
import { calculatorConstants, mediaQueries } from '../../data/Constants'
import { Translations } from '../../data/Translations'
import { selectDeposit, selectMonths, selectPrice, updateDeposit, updatePrice, updateMonths } from '../../store/loan'
import Overview from './overview/Overview'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import { ICalculatorData } from '../../prismicTypes'
import IconInfo from '../../public/svg/IconInfo'
import { useRouter } from 'next/router'
import { getCalculatorDataFromLoanType, getIdealDepositAndContractLengthForVehicle } from '../../utils/utils'
import { containsBilasolurParameters } from '../../utils/helpers'
import { RichTextBlock } from 'prismic-reactjs'
import { IBilasolurResponse } from '../../types'
import { getMonthsSinceDate } from '../../utils/helpers'

interface IProps {
  calculator_data: ICalculatorData[]
  loan_type: number
  loan_subType: number
  tooltip_green: RichTextBlock[]
  onCarInfoUpdate(carInfo: IBilasolurResponse): void
}

const Calculator: React.FC<IProps> = ({ calculator_data, loan_type, loan_subType = 0, onCarInfoUpdate }) => {
  const { calculator } = Translations.is
  const isTabletOrSmaller = useMediaQuery(mediaQueries.isTabletOrSmaller)
  const dispatch = useDispatch()
  const router = useRouter()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const isbilasolurReferenced = containsBilasolurParameters(router.query)
  const [minDepositAmount, setMinDepositAmount] = useState(calculatorConstants.MIN_DEPOSIT_AMOUNT)
  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)
  const loanPercentage = ((price - deposit) / price) * 100
  const depositPercentage = (deposit / price) * 100
  const months = useSelector(selectMonths)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const calculatorData = getCalculatorDataFromLoanType(calculator_data, loan_type, loan_subType)
  //This baseLoanData is used to calculate the premium on current loan...
  const baseLoanData = getCalculatorDataFromLoanType(calculator_data, 77, 0)
  const maxDeposit = calculatorConstants.MAX_PRICE_AMOUNT - calculatorConstants.MIN_LOAN_AMOUNT

  const handleCarInfoUpdate = (updatedCarInfo: IBilasolurResponse) => {
    onCarInfoUpdate(updatedCarInfo)
    //update the loan parameters
    const idealCalculatorSetUp = getIdealDepositAndContractLengthForVehicle(
      updatedCarInfo.Verd,
      getMonthsSinceDate(updatedCarInfo.NyskradurAr, updatedCarInfo.NyskradurManudur)
    )
    dispatch(updateMonths(idealCalculatorSetUp.idealContractLength))
    dispatch(updateDeposit(idealCalculatorSetUp.idealDepositAmount))
  }

  useEffect(() => {
    const tenPercentOfPrice = price * calculatorConstants.MIN_DEPOSIT_PERCENTAGE
    // Here we are adjusting the Deposit MIN for the slider
    if (tenPercentOfPrice < calculatorConstants.MIN_DEPOSIT_AMOUNT) {
      setMinDepositAmount(calculatorConstants.MIN_DEPOSIT_AMOUNT)
    } else if (price > calculatorConstants.MAX_PRICE_AMOUNT) {
      setMinDepositAmount(maxDeposit)
    } else {
      setMinDepositAmount(tenPercentOfPrice)
    }
    //Here we are updating the deposit if Price gets too low or too high.
    if (deposit < tenPercentOfPrice) {
      if (tenPercentOfPrice < calculatorConstants.MIN_DEPOSIT_AMOUNT) {
        dispatch(updateDeposit(calculatorConstants.MIN_DEPOSIT_AMOUNT))
      } else {
        dispatch(updateDeposit(tenPercentOfPrice))
      }
    } else if (deposit > price - calculatorConstants.MIN_LOAN_AMOUNT) {
      dispatch(updateDeposit(price - calculatorConstants.MIN_LOAN_AMOUNT))
    }
  }, [price])

  return (
    <SCCalculatorWrapper>
      <SCBilasolurWrapper>
        <SCWrapper>
          <SCInputGroupWrapper>
            <CalculatorInput
              name="price"
              value={price}
              onChange={(newPrice) => dispatch(updatePrice(newPrice))}
              suffix={calculatorConstants.CURRENCY_SUFFIX}
              max={calculatorConstants.MAX_PRICE_AMOUNT}
              min={calculatorConstants.MIN_PRICE_AMOUNT}
              step={calculatorConstants.SLIDER_STEP_AMOUNT}
            />
            <CalculatorInput
              name="deposit"
              value={deposit}
              onChange={(newDeposit) => dispatch(updateDeposit(newDeposit))}
              suffix={calculatorConstants.CURRENCY_SUFFIX}
              min={minDepositAmount}
              max={price - calculatorConstants.MIN_LOAN_AMOUNT}
              step={calculatorConstants.SLIDER_STEP_DEPOSIT}
              depositPercentage={depositPercentage}
            />
            <CalculatorInput
              name="contractLength"
              value={months}
              onChange={(newMonths) => dispatch(updateMonths(newMonths))}
              suffix={calculatorConstants.MONTHS_SUFFIX}
              min={calculatorConstants.MIN_CONTRACT_LENGTH}
              max={calculatorConstants.MAX_CONTRACT_LENGTH}
              step={calculatorConstants.SLIDER_STEP_MONTHS}
            />
          </SCInputGroupWrapper>

          <Overview
            calculator_data={calculator_data}
            loan_type={loan_type}
            loan_subType={loan_subType}
            loanPercentage={loanPercentage}
          />
        </SCWrapper>

        {isTabletOrSmaller && (
          <SCOverviewButtonContainer>
            <OverviewButton />
          </SCOverviewButtonContainer>
        )}
        <SCButtonsContainer>
          <SCBreakdownButton onClick={handleOpen}>
            <SCBreakdown>
              <IconInfo />
              <SCButtonText>{calculator.breakdown}</SCButtonText>
            </SCBreakdown>
          </SCBreakdownButton>
        </SCButtonsContainer>
        {isbilasolurReferenced && (
          <BilasolurCard onCarInfoUpdate={(updatedCarInfo) => handleCarInfoUpdate(updatedCarInfo)} />
        )}
      </SCBilasolurWrapper>
      {isOpen && (
        <BreakdownModal
          isOpen={isOpen}
          handleClose={handleClose}
          calculatorData={calculatorData}
          baseLoanData={baseLoanData}
        />
      )}
    </SCCalculatorWrapper>
  )
}

export default Calculator

const SCCalculatorWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    grid-column: 1/-1;
    z-index: 100;

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 8rem;
      grid-column: 1/11;
    }
  `}
`

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      justify-content: space-between;
      margin-right: 5rem;
      flex-direction: row;
    }
  `}
`

const SCBilasolurWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      flex-direction: column;
    }
    @media screen and (max-width: ${breakpoints.tabletMin}) {
      #bilasolurcard {
        order: -1;
      }
    }
  `}
`
const SCInputGroupWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: block;
    width: 100%;
    margin-right: 0;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-right: 3rem;
    }

    @media screen and (min-width: ${breakpoints.tabletMid}) {
      width: 21.5rem;
      margin-right: 4.5rem;
    }

    @media screen and (min-width: ${breakpoints.tabletMax}) {
      width: 25rem;
      margin-right: 3.5rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMax}) {
      width: 25rem;
      margin-right: 5.5rem;
    }
  `}
`

const SCBreakdown = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`

const SCButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const SCButtonText = styled.span`
  ${({ theme: { colors } }) => css`
    ${Typography.buttonText};
    margin-left: 0.75rem;
    transition: all 320ms ease-in-out;
    border-bottom: 0.125rem solid ${colors.white};
    ${SCBreakdown}:hover & {
      border-bottom: 0.125rem solid ${colors.blue200};
    }
  `}
`

const SCOverviewButtonContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const SCBreakdownButton = styled.button`
  ${({ theme: { breakpoints } }) => css`
    margin-top: 0;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-top: 2rem;
      max-width: 13rem;
    }
  `}
`

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import { ICalculatorCard } from '../../prismicTypes'
import Calculator from '../calculator/Calculator'
import Slice from '../Slice'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import SelectButtonPoll from '../SelectButtonPoll'
import IconLeaf from '../../public/svg/IconLeaf'
import { createSelectPollID, fetchLoanIdsFromString } from '../../utils/utils'
import { IBilasolurResponse } from '../../types'

// Default Values
export const LOAN_TYPE = 77 // Lykillán = 77, Lykilsamningur = 57
export const LOAN_SUBTYPE = 100
export const CALCULATOR_ID = 'calculator'
export const CO2WLTP_LIMIT = 50
export const CO2_LIMIT = 50
interface IProps {
  sliceType: string
  slice: ICalculatorCard
}

const shouldDisplayImage = (query: ParsedUrlQuery) => {
  return query?.page !== 'reiknivel'
}

export const CalculatorCard: React.FC<IProps> = ({
  sliceType,
  slice: { title, image, tooltip_green, calculator_data, loan_type_buttons, loan_types_text },
}) => {
  const router = useRouter()
  //Create the buttons
  const pollItems = loan_type_buttons.map((buttonItem) => {
    const tempID = createSelectPollID(buttonItem.loan_type_id, buttonItem.loan_subtype_id)
    const pollItem = { id: tempID, name: buttonItem.text }
    return pollItem
  })
  const [loanTypeId, setloanTypeId] = useState<string>(createSelectPollID(77, 100))
  const [loanType, setLoanType] = useState<number>(LOAN_TYPE)
  const [loanSubType, setLoanSubType] = useState<number>(LOAN_SUBTYPE)
  const handleLoanTypeClick = (id: string) => {
    setloanTypeId(id)
    const loanTypeIds = fetchLoanIdsFromString(id)
    setLoanType(parseInt(loanTypeIds.loan_type))
    setLoanSubType(parseInt(loanTypeIds.loan_subType))
  }
  //If the user comes from bilasolur.is, then do this...
  const handleCarInfoUpdate = (updatedCarInfo: IBilasolurResponse) => {
    if (updatedCarInfo?.Electric) {
      //If the vehicle is fully electric
      handleLoanTypeClick('77--100')
    } else if (
      (updatedCarInfo?.CO2WLTP < CO2WLTP_LIMIT && updatedCarInfo?.CO2WLTP != 0) ||
      (updatedCarInfo?.CO2 < CO2_LIMIT && updatedCarInfo?.CO2 != 0)
    ) {
      //If the vehicle fulfills CO2 standards for a discount, then it's the same as hybrid...

      handleLoanTypeClick('77--110')
    } else {
      // Else it's a petrol vehicle and does not fulfill CO2 standards for a loan discount
      handleLoanTypeClick('77--0')
    }
  }
  return (
    <Slice sliceType={sliceType} gridStyle={SCCalculator}>
      <SCHeading id={CALCULATOR_ID}>{title[0].text}</SCHeading>
      <SCCalculatorTypes id="CalculatorLoanTypes">
        <SelectButtonPoll
          pollItems={pollItems}
          defaultItem={loanTypeId}
          onPollChange={(id) => handleLoanTypeClick(id)}
        />
        <SCLoanTypeTexts>
          {loan_types_text.map((item, index) => {
            if (item.loan_subtype_id === loanSubType && item.loan_type_id === loanType)
              return (
                <SCLoanTypeInfo key={`loan_type_text-${index}`}>
                  {item.icon === 'greenLeaf' && <IconLeaf />}
                  <SCLoanText isIcon={item.icon === 'None'}>{item.text}</SCLoanText>
                </SCLoanTypeInfo>
              )
          })}
        </SCLoanTypeTexts>
      </SCCalculatorTypes>
      <Calculator
        calculator_data={calculator_data}
        loan_type={loanType}
        loan_subType={loanSubType}
        tooltip_green={tooltip_green}
        onCarInfoUpdate={(updatedCarInfo) => handleCarInfoUpdate(updatedCarInfo)}
      />
      {shouldDisplayImage(router.query) && (
        <SCImageWrapper>
          <SCWhiteOverlay />
          <SCImage imageUrl={image.url}></SCImage>
        </SCImageWrapper>
      )}
    </Slice>
  )
}
export default CalculatorCard

const SCCalculator = css`
  margin-bottom: 10rem;
`
const SCLoanTypeTexts = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.desktopMidPlus}) {
      flex-direction: row;
    }
  `}
`
const SCLoanTypeInfo = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 8px;
    padding: 10px 0;
    align-items: center;
    justify-content: flex-start;
    svg {
      min-width: 32px;
    }
    @media screen and (min-width: ${breakpoints.desktopMidPlus}) {
      padding-right: 20px;
    }
  `}
`
const SCLoanText = styled.div<{ isIcon: boolean }>`
  ${({ isIcon, theme: { breakpoints } }) => css`
    ${Typography.pSmall};
    font-weight: 700;
    padding-left: ${isIcon ? '38px' : '5px'};
    @media screen and (max-width: ${breakpoints.mobileMid}) {
      font-size: 0.875rem;
    }
  `}
`
const SCCalculatorTypes = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: -1/1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-bot: 20px;
    //width: 100%;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      width: 46%;
    }
  `}
`
const SCImageWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    height: 21.5rem;
    grid-column: 1/-1;
    margin-top: 5.5rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      height: 100%;
      width: 60%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 50;
      transform: translateX(3rem);
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      width: 55%;
    }
  `}
`
const SCWhiteOverlay = styled.span`
  ${({ theme: { colors, breakpoints } }) => css`
    display: none;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      background-color: ${colors.white};
      border-radius: 0 0.75rem 0.75rem 0;
      display: block;
      position: absolute;
      width: 18rem;
      height: 30rem;
      bottom: 7.5rem;
      left: -1px;
    }

    @media screen and (min-width: ${breakpoints.tabletMid}) {
      width: 20rem;
    }

    @media screen and (min-width: ${breakpoints.tabletMax}) {
      min-width: 23rem;
      bottom: 6.25rem;
      height: 31rem;
    }
    @media screen and (width: ${breakpoints.tabletMax}) {
      height: 36rem;
    }
    @media screen and (width: ${breakpoints.desktopMin}) {
      height: 35rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMid}) {
      width: 84%;
      min-width: 26rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMidPlus}) {
      min-width: 26rem;
      width: 78%;
    }

    @media screen and (min-width: ${breakpoints.desktopMax}) {
      width: 64%;
      min-width: 26rem;
    }
  `}
`

const SCImage = styled.div<{ imageUrl: string }>`
  ${({ imageUrl, theme: { breakpoints } }) => css`
    background-image: url(${imageUrl});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.625rem 0 0 0.625rem;
    height: 100%;
    margin-right: -1rem;
    width: calc(100% + 1rem);

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      width: 100%;
      margin-right: 0;
      border-radius: 0.625rem;
    }
  `}
`

const SCHeading = styled.h2`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h2};
    margin: 0 0 2rem;
    grid-column: 1/-1;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin: 7.5rem 0 4rem;
      margin: 10rem 0 4rem;
      grid-column: 1/3;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin: 10rem 0 4rem;
      grid-column: 1/5;
    }
  `}
`

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { IHitProps } from '../../../types/Search'
import Typography from '../../../styles/typography'
import { useRouteChange } from '../../RoutingProvider'
import Image, { ImageProps } from 'next/image'

interface IProps {
  hits: IHitProps[]
}

const SearchOverviewHitsResults = ({ hits }: IProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { handleRouteChange } = useRouteChange()
  return (
    <>
      {hits.map((hit) => (
        <SItem key={hit.objectID}>
          <SAnchor
            href={hit.slug}
            onClick={(event) => {
              handleRouteChange({
                event,
                url: hit.slug,
                isNextLink: true,
              })
            }}
          >
            {hit.image != '' && (
              <SImageContainer>
                <SCardImage
                  src={hit.image}
                  layout="fixed"
                  width={120}
                  height={120}
                  alt=""
                  $isLoaded={imageLoaded}
                  onLoad={() => setImageLoaded(true)}
                />
              </SImageContainer>
            )}

            <SCPageItem>
              <SCPageItemHeader>
                {hit.title != 'null' ? <SCTitle>{hit.title}</SCTitle> : <SCTitle />}
                <SCSlug>{hit.slug.substr(0, hit.slug.indexOf('#'))}</SCSlug>
              </SCPageItemHeader>
              <SCSmallParagraph>{hit.description}</SCSmallParagraph>
            </SCPageItem>
          </SAnchor>
        </SItem>
      ))}
    </>
  )
}

export default SearchOverviewHitsResults

const SCPageItemHeader = styled.div`
  display: flex;
  width: 100%;
`
const SCPageItem = styled.div`
  display: block;
  width: 100%;
`

const SCSlug = styled.p`
  ${Typography.pSmall}
  flex: auto;
  text-align: right;
  margin: 0;
`

const SCTitle = styled.h6`
  ${Typography.h6}
  text-align: left;
  margin: 0;
`
const SItem = styled.li`
  margin-top: 28px;
  list-style: none;
`
const SCardImage = styled(Image)<ImageProps & { $isLoaded?: boolean }>`
  ${({ theme: { colors }, $isLoaded }) => css`
    background-color: ${colors.white};
    border-radius: 99px;
    opacity: ${$isLoaded ? 1 : 0};
    transition: opacity 1s;
  `}
`
const SImageContainer = styled.div`
  display: flex;
  margin-right: 28px;
  transition: transform 320ms ease, opacity 320ms ease;

  img {
    padding: 8px !important;
  }
`
const SAnchor = styled.a`
  ${({ theme: { colors } }) => css`
    display: flex;
    padding: 12px;
    background-color: ${colors.white};
    transition: background-color 320ms ease;
    border-radius: 1rem;
    &:focus,
    &:hover,
    &.focus-visible {
      background-color: ${colors.orange400};
    }
    &.focus-visible {
      border-radius: 12px;
    }
  `}
`

const SCSmallParagraph = styled.span`
  ${Typography.pSmall}
  display: -webkit-box;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

import React, { useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Field, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useRef, useState } from 'react'
import Select from 'react-select'
import DropdownIndicator from '../ContactUs/DropdownIndicator'
import * as Sentry from '@sentry/nextjs'
import Toast from '@kvika/lykill-toasts'
import toast, { Toaster } from 'react-hot-toast'
import { Translations } from '../../data/Translations'

const CONTACT_US_OPTIONS = [
  {
    value: 'general',
    label: 'Almenn fyrirspurn',
  },
  {
    value: 'compliance',
    label: 'Kvörtun',
  },
]

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    width: '100%', // Set a custom width for the dropdown container

    borderColor: '#456372',
    marginLeft: '-14px',
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? 'none' : '1px solid #ccc',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      border: state.isFocused ? 'none' : '1px solid #ccc',
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    color: '#8C8C93',
    backgroundColor: state.isSelected ? '#ececec' : 'white',

    fontWeight: '700',
    fontFamily: 'AreaNormal',
    padding: '20px',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#8C8C93',
    fontWeight: '700',
    fontFamily: 'AreaNormal',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

import { IContactUsSlice } from '../../prismicTypes'
import Slice from '../Slice'
import PrismicRichText from '../PrismicRichText'
import sendContactForm from '../ContactUs/send-form'
import {
  calculateCorrectAnswer,
  convertRandomMathExpressionToString,
  generateRandomMathExpression,
} from '../../utils/helpers'

interface IProps {
  slice: IContactUsSlice
}

export type ContactUsError = 'recaptcha_not_available' | 'recaptcha_failed' | 'error_sending_contact_form'

const ContactUsSlice: React.FC<IProps> = ({ slice: { slice_type, primary } }) => {
  const formikRef = useRef<FormikProps<any>>(null)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<ContactUsError | undefined>()

  const [randomMathExpression, setRandomMathExpression] = useState<string>()

  useEffect(() => {
    setRandomMathExpression(generateRandomMathExpression())
  }, [])

  const handleError = useCallback(
    async (message: ContactUsError, error?: Error) => {
      Sentry.captureMessage(message, {
        level: 'error',
        extra: {
          error,
        },
      })
      setError(message)
    },
    [error]
  )

  const handleSubmitForm = useCallback(async (values): Promise<Response | void> => {
    try {
      // Send contact form
      const contactResponse = await sendContactForm(
        Object.assign(values, {
          requestType: values.inquryType.value,
          recaptcha: values.recaptcha,
          randomMathExpression: values.randomMathExpression,
        })
      )

      if (!contactResponse.ok) {
        await handleError('error_sending_contact_form')
        toast.custom(
          <StyledToast>
            <Toast
              variant={'error'}
              title={Translations.is.contact_us.failed_submission_text}
              description=""
              showButtons={false}
              onContinue={() => {}}
              onClose={() => {}}
            />
          </StyledToast>
        )
        return
      }

      toast.custom(
        <StyledToast>
          <Toast
            variant={'info'}
            title={Translations.is.contact_us.successful_submission_text}
            description=""
            showButtons={false}
            onContinue={() => {}}
            onClose={() => {}}
          />
        </StyledToast>
      )

      return contactResponse
    } catch (err) {
      await handleError('error_sending_contact_form', err instanceof Error ? err : undefined)
      toast.custom(
        <StyledToast>
          <Toast
            variant={'error'}
            title={Translations.is.contact_us.failed_submission_text}
            description=""
            showButtons={false}
            onContinue={() => {}}
            onClose={() => {}}
          />
        </StyledToast>
      )
      return
    }
  }, [])

  return (
    <Slice sliceType={slice_type} gridStyle={SCGridStyle}>
      <Toaster position="bottom-right" reverseOrder={false} toastOptions={{ duration: 3000 }} />

      <SCCardGridContainer>
        <TextContainer>
          <PrismicRichText richText={primary.contact_us_title} />
          <PrismicRichText richText={primary.contact_us_body} />
        </TextContainer>

        <Wrapper responseForm={submitted}>
          <FormWrapper>
            <Formik
              innerRef={formikRef}
              validateOnMount={true}
              initialValues={{
                inquryType: CONTACT_US_OPTIONS[0],
                name: '',
                email: '',
                subject: '',
                message: '',
                recaptcha: '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(),
                email: Yup.string().email().required(),
                subject: Yup.string().required(),
                message: Yup.string().required(''),
                recaptcha: Yup.number()
                  .test('recaptcha', 'Rangt svar', (value) => {
                    const correctAnswer = calculateCorrectAnswer(randomMathExpression!)
                    return value?.toString() === correctAnswer
                  })
                  .required('Nauðsynlegur reitur'),
              })}
              onSubmit={async (values, formikBag) => {
                formikBag.setSubmitting(true)
                setSubmissionError(undefined)

                const response = await handleSubmitForm({
                  ...values,
                  feedbackType: values.inquryType.value,
                  randomMathExpression: randomMathExpression,
                })

                formikBag.setSubmitting(false)

                if (response) {
                  formikBag.resetForm()
                  setSubmitted(true)

                  setRandomMathExpression(generateRandomMathExpression())
                } else {
                  setSubmissionError('Ekki tókst að senda fyrirspurn, vinsamlegast reyndu aftur síðar')
                }
              }}
            >
              {(formikProps) => {
                const { isValid } = formikProps
                const { isSubmitting } = formikProps

                console.log(formikProps.touched.message)

                return (
                  <Form onReset={formikProps.handleReset} onSubmit={formikProps.handleSubmit}>
                    <>
                      <StyledSelect
                        placeholder="Tegund fyrirspurnar"
                        id="inquryType"
                        name="inquryType"
                        options={CONTACT_US_OPTIONS}
                        onChange={(val: any) => {
                          formikProps.setFieldValue('inquryType', val)
                        }}
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                        defaultValue={CONTACT_US_OPTIONS.find((option) => option.value === 'general')}
                      />
                      {formikProps.errors.inquryType && formikProps.touched.inquryType ? (
                        <span style={{ color: 'red' }}>{formikProps.errors.inquryType}</span>
                      ) : null}

                      <StyledField
                        id="name"
                        name="name"
                        placeholder="Nafn"
                        isSelected={formikProps.errors.name && formikProps.touched.name}
                      />

                      <StyledField
                        id="email"
                        name="email"
                        placeholder="Netfang"
                        isSelected={formikProps.errors.email && formikProps.touched.email}
                      />

                      <StyledField
                        id="subject"
                        name="subject"
                        placeholder="Viðfang fyrirspurnar"
                        isSelected={formikProps.errors.subject && formikProps.touched.subject}
                      />

                      <Field
                        as={Textarea}
                        id="message"
                        name="message"
                        placeholder="Skilaboð"
                        style={{
                          borderColor:
                            formikProps.touched.message && formikProps.values.message.length === 0 ? 'red' : '#dedee0',
                        }}
                      />

                      <StyledField
                        id="recaptcha"
                        name="recaptcha"
                        placeholder={convertRandomMathExpressionToString(randomMathExpression)}
                      />
                      {formikProps.errors.recaptcha && formikProps.touched.recaptcha ? (
                        <span style={{ color: 'red', width: '70%' }}>{formikProps.errors.recaptcha}</span>
                      ) : null}

                      <SubmitButton
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        onClick={() => formikProps.submitForm()}
                      >
                        Senda
                      </SubmitButton>

                      {submissionError && (
                        <SubmissionErrorWrapper>
                          <span style={{ color: 'red' }}>
                            {submissionError || 'Ekki tókst að senda fyrirspurn, vinsamlegast reyndu aftur síðar'}
                          </span>
                        </SubmissionErrorWrapper>
                      )}
                    </>
                  </Form>
                )
              }}
            </Formik>
          </FormWrapper>
        </Wrapper>
      </SCCardGridContainer>
    </Slice>
  )
}

export default ContactUsSlice

const StyledToast = styled.div`
  > div {
    padding-top: 16px;
    h5 {
      margin: 0;
      margin-top: 7px;
      line-height: 20px;
    }

    svg {
      width: 40px;
      height: 40px;
    }

    > div {
      button {
        color: #456372;
      }
    }
  }
`

const SCGridStyle = css`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 2rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 10.5rem;
    }
  `}
`

const SCCardGridContainer = styled.div`
  ${({ theme: { breakpoints } }) => css`
    margin-top: 4rem;

    grid-column: 1/-1;
    display: grid;

    padding-right: 2rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 5rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMid}) {
      grid-column-gap: 9rem;
    }
  `}
`

const Wrapper = styled.div<{ responseForm: boolean }>`
  ${({ theme: { breakpoints } }) => css`
    padding: 96px 80px;

    grid-column: 5/10;

    background: var(--grey-white, #fff);
    box-shadow: 0px 4px 56px 0px rgba(0, 0, 0, 0.07);
    border-radius: 48px;

    @media screen and (max-width: ${breakpoints.desktopMin}) {
      grid-column: 1 / 12;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  `}
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`

const Form = styled.form`
  display: contents;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  grid-column: 1/5;

  margin-top: 5rem;

  h3 {
    font-size: 4rem;
    line-height: 4.5rem;
    font-style: normal;
    font-weight: 900;

    color: var(--brand-color-secondary-400, var(--blue-blue-400, #456372));

    margin-bottom: 0rem;
  }

  p {
    color: var(--blue-blue-400, #456372);

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  ${({ theme: { breakpoints } }) => css`
    @media screen and (max-width: ${breakpoints.desktopMin}) {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
  `}
`

const StyledSelect = styled(Select)`
  background: white;

  border: 1px solid #dedee0;
  border-radius: 10px;
  height: 88px;

  padding: 15px;

  color: #8c8c93;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  * {
    border: none !important;
  }
`

const StyledField = styled(Field)`
  background: white;

  border: 1px solid #dedee0;
  border-radius: 10px;
  height: 88px;

  padding: 15px;

  color: #8c8c93;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-left: 22px;

  font-family: 'AreaNormal';

  border-color: ${(props) => (props.isSelected ? `red` : `#dedee0`)};
`

const Textarea = styled.textarea<{ isSelected: boolean }>`
  background: white;
  border: 1px solid #dedee0;
  border-radius: 10px;
  padding: 15px;
  color: #8c8c93;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-left: 22px;
  font-family: 'AreaNormal';
  padding-bottom: 188px;
  padding-top: 24px;

  border-color: ${(props) => (props.isSelected ? `red` : `#dedee0`)};
`

const SubmitButton = styled.button`
  border-radius: 8px;
  background: var(--yellow-yellow-400, #f8bf23);
  transition: all 0.5s;

  height: 88px;

  color: var(--black-black-400, #363636);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 106.667% */
  letter-spacing: 0.15px;

  :hover {
    background-color: #ffd257;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: var(--gray-gray-300, #cccccc);
      color: var(--gray-gray-700, #777777);
      cursor: not-allowed;

      :hover {
        cursor: not-allowed;
        background: var(--gray-gray-300, #cccccc);
      }
    `}
`

const SubmissionErrorWrapper = styled.div`
  position: relative;
`

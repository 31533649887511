import React from 'react'
import styled, { css } from 'styled-components'

import { ITwoColumnInfoCardsSlice } from '../../prismicTypes'
import TwoColumnInfoCard from '../TwoColumnInfoCard'
import Typography from '../../styles/typography'
import { replaceDoubleHyphen } from '../../utils/helpers'
import Slice from '../Slice'

interface IProps {
  slice: ITwoColumnInfoCardsSlice
}

const TwoColumnInfoCards: React.FC<IProps> = ({
  slice: {
    slice_type,
    primary: { two_column_info_title },
    items,
  },
}) => {
  return (
    <Slice sliceType={slice_type} gridStyle={SCGridStyle}>
      <SCTitle>{replaceDoubleHyphen(two_column_info_title)}</SCTitle>
      <SCCardGridContainer>
        {items.map((card) => {
          return (
            <TwoColumnInfoCard
              key={card.two_column_info_card_title}
              title={card.two_column_info_card_title}
              description={card.two_column_info_card_description}
            />
          )
        })}
      </SCCardGridContainer>
    </Slice>
  )
}

export default TwoColumnInfoCards

const SCGridStyle = css`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 5.5rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 17rem;
    }
  `}
`

const SCTitle = styled.h3`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h3}
    grid-column: 1 / -1;
    margin-bottom: 1rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-column: 1 / 6;
      margin-bottom: 4.5rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-column: 1 / 9;
    }
    @media screen and (min-width: ${breakpoints.desktopMid}) {
      grid-column: 1 / 7;
    }
  `}
`

const SCCardGridContainer = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1/-1;
    display: grid;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 2rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 10rem;
    }

    @media screen and (min-width: ${breakpoints.desktopMid}) {
      grid-column-gap: 12rem;
    }
  `}
`

import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import Link from 'next/link'
import Paragraph from '../Paragraph'
import { getISDateFormat } from '../../utils/helpers'

export interface IProps {
  title: string
  description: string
  date?: Date
  href: string
  className?: string
}

const NewsArticlePageCard: React.FC<IProps> = ({ className, title, description, date, href }) => {
  return (
    <SCCard className={className} key={title}>
      {date && <Paragraph extraStyle={Typography.pSmall}>{getISDateFormat(date)}</Paragraph>}
      <Link href={href} passHref>
        <SCTitle>{title}</SCTitle>
      </Link>
      <Paragraph extraStyle={Typography.pSmall}>{description}</Paragraph>
    </SCCard>
  )
}

export default NewsArticlePageCard

const SCTitle = styled.h4`
  ${({ theme: { colors } }) => css`
    ${Typography.h4}
    text-decoration: underline;
    text-decoration-color: ${colors.blue200};
    cursor: pointer;
    &:hover {
      text-decoration-color: ${colors.blue400};
    }
  `}
`

const SCCard = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`

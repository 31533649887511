import Image from 'next/image'
import { useRef } from 'react'
import styled, { css } from 'styled-components'
import { ICalculatorData } from '../../../../prismicTypes'
import { useOnClickOutside } from '../../../../utils/hooks'
import { useTooltipContext } from '../OverviewItem'
import OverviewTooltip from './OverviewTooltip'

interface IProps {
  calculatorData: ICalculatorData
}

const TooltipWrapper: React.FC<IProps> = ({ calculatorData }) => {
  const { isOpen, toggleTooltip } = useTooltipContext()
  const ref = useRef(null)

  const handleTooltipClose = () => {
    if (isOpen) {
      toggleTooltip()
    }
  }
  useOnClickOutside(ref, handleTooltipClose)
  return (
    <SCContainer>
      <SCInfoButton onClick={toggleTooltip} tabIndex={0}>
        <Image src="/svg/info_small.svg" width="24" height="24" alt="Info_Tooltip" />
      </SCInfoButton>
      <div ref={ref}>
        <SCOverviewWrapper isOpen={isOpen}>
          <OverviewTooltip calculatorData={calculatorData} />
        </SCOverviewWrapper>
      </div>
    </SCContainer>
  )
}

export default TooltipWrapper

const SCContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
`

const SCInfoButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
`

const SCOverviewWrapper = styled.div<{ isOpen: boolean }>`
  ${({ isOpen, theme: { breakpoints } }) => css`
    opacity: ${isOpen ? 1 : 0};
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      transition: opacity 320ms ease-in-out;
    }
  `}
`

const sendContactForm = async (values: {
  name: string
  email: string
  subject: string
  message: string
  requestType: {
    value: string
    label: string
  }
  recaptcha?: string
  randomMathExpression: string
}) => {
  let body = {
    name: values.name,
    email: values.email,
    subject: values.subject,
    message: values.message,
    feedbackType: values.requestType,
    company: 'Lykill',
    recaptcha: values.recaptcha,
    randomMathExpression: values.randomMathExpression,
  }

  return fetch('/api/contact-us/', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export default sendContactForm

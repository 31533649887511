import NumberFormat, { NumberFormatValues } from 'react-number-format'
import styled, { css } from 'styled-components'
import { ExtraStyle } from '../../types/Types'
import nextId from 'react-id-generator'
interface IProps {
  decimalScale?: number
  suffix?: string
  id?: string
  value: string | number
  extraStyle?: ExtraStyle
  displayType?: 'text' | 'input'
  max?: number
  allowNegative?: boolean
  onChange?(newValue: number): void
}

const FormattedNumber: React.FC<IProps> = ({
  decimalScale = 0, // don't allow decimals by default
  suffix = ' kr.',
  value,
  extraStyle,
  displayType = 'input',
  id = nextId(),
  max = 100000000,
  allowNegative = false,
  onChange,
}) => {
  const handleOnClick = (e: MouseEvent) => {
    if (displayType === 'input') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.target.select()
    }
  }
  return (
    <SCNumberFormat
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={decimalScale}
      id={id}
      fixedDecimalScale
      allowNegative={allowNegative}
      allowEmptyFormatting={true} // display suffix when empty
      suffix={suffix}
      value={value}
      extrastyle={extraStyle}
      displayType={displayType}
      type="tel" // use number keyboard in mobile
      onValueChange={(values: NumberFormatValues) => onChange && onChange(values.floatValue ?? 0)}
      onClick={(e: MouseEvent | null) => {
        if (e != undefined) {
          handleOnClick(e)
        }
      }}
      isAllowed={(values: NumberFormatValues) => {
        const { floatValue } = values
        if (floatValue === undefined) {
          return true
        } else {
          return floatValue >= 0 && floatValue <= max
        }
      }}
    />
  )
}

export default FormattedNumber

const SCNumberFormat = styled(NumberFormat)<{ extrastyle?: ExtraStyle }>`
  ${({ extrastyle, theme: { breakpoints } }) => css`
    ${extrastyle}
    @media screen and (max-width: ${breakpoints.mobileMax}) {
      font-size: 16px;
      margin-top: 0px;
    }
  `}
`

import FormattedNumber from '../../../common/FormattedNumber'
import styled, { css } from 'styled-components'
import Typography from '../../../../styles/typography'

interface IProps {
  deposit: number
  interestRate: number
  selected: boolean
  handleOnClick(): void
  isVisible: boolean
}

const TooltipButton: React.FC<IProps> = ({ deposit, interestRate, selected, handleOnClick, isVisible }) => {
  return (
    <SCDepositButton tabIndex={isVisible ? 0 : -1} selected={selected} onClick={handleOnClick}>
      <FormattedNumber value={deposit} displayType="text" extraStyle={SCFormattedNumber} />
      <FormattedNumber
        value={interestRate}
        displayType="text"
        extraStyle={SCFormattedNumber}
        decimalScale={2}
        suffix="%"
      />
    </SCDepositButton>
  )
}

export default TooltipButton

const SCDepositButton = styled.button<{ selected: boolean }>`
  ${({ selected, theme: { colors } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    width: 100%;
    height: 3.5rem;
    margin-top: 0.5rem;
    background-color: ${selected ? colors.orange400 : colors.white};
    border: ${selected ? 'none' : `1px solid ${colors.grey200}`};
    transition: background-color 320ms ease-in-out;
    &:hover {
      background-color: ${colors.buttonHover};
    }
  `}
`

const SCFormattedNumber = css`
  ${({ theme: { colors } }) => css`
    ${Typography.pSmall}
    color: ${colors.black400};
  `}
`

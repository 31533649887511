import Head from 'next/head'
import { Translations } from '../../data/Translations'
import { ISEOData } from '../../utils/seoHelpers'

interface IProps {
  seoData: ISEOData
}

export const baseUrl =
  process.env.VERCEL_ENV === 'production' ? 'www.lykill.is' : process.env.VERCEL_URL || 'www.lykill.is'

export const NextHeader = ({ seoData }: IProps) => {
  const { openGraphText } = Translations.is
  const ogTitle = seoData.ogTitle != undefined && seoData.ogTitle.length > 0 ? seoData.ogTitle : openGraphText.title
  const ogDescription =
    seoData.ogDescription != undefined && seoData.ogDescription.length > 0
      ? seoData.ogDescription
      : openGraphText.description
  const ogImage = seoData.ogImage != undefined && seoData.ogImage.length > 0 ? seoData.ogImage : openGraphText.image
  return (
    <Head>
      <link rel="preload" href="/fonts/Area-Black.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/Area-Bold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/Area-Extrablack.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/Area-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/Area-Semibold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <title>{`Lykill ${seoData.metaTitle ? `- ${seoData.metaTitle}` : ''}`}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {seoData.noindex && <meta name="robots" content="noindex" />}
      {seoData.metaDescription && <meta name="description" content={seoData.metaDescription} />}
      {(seoData.links.canonical || seoData.links.canonical === '') && (
        <link rel="canonical" href={`https://${baseUrl}${seoData.links?.canonical}`} />
      )}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
    </Head>
  )
}

export default NextHeader

import React from 'react'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import { IFeaturedPagesSlice } from '../../prismicTypes'
import PrismicRichText from '../PrismicRichText'
import Slice from '../Slice'
import { replaceDoubleHyphen } from '../../utils/helpers'
import PrismicInlineLink from '../Links/PrismicInlineLink'
import { Translations } from '../../data/Translations'

interface IProps {
  slice: IFeaturedPagesSlice
}
const FeaturedPages: React.FC<IProps> = ({ slice: { items } }: IProps) => {
  const { common } = Translations.is

  return (
    <Slice gridStyle={SCGrid}>
      {items.map(({ title, label, description, image, link }, index) => (
        <SCWrapper key={title[0].text}>
          <SCImage alignImageRight={index % 2 !== 0} imageUrl={image.url} />
          <SCContent alignImageRight={index % 2 !== 0}>
            {label && <SCHeaderLabel>{label[0].text}</SCHeaderLabel>}
            <SCHeaderH1>{replaceDoubleHyphen(title[0].text)}</SCHeaderH1>
            <SCDescription>
              <PrismicRichText richText={description} />
            </SCDescription>
            <PrismicInlineLink link={link}>{common.checkItOut}</PrismicInlineLink>
          </SCContent>
        </SCWrapper>
      ))}
    </Slice>
  )
}
export default FeaturedPages

const SCGrid = css`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 5rem @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 9rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-bottom: 15rem;
    }
  `}
`

const SCWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    @media screen and (min-width: ${breakpoints.tabletMax}) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`

const SCContent = styled.div<{ alignImageRight: boolean }>`
  ${({ alignImageRight, theme: { colors, breakpoints } }) => css`
    border-radius: 0.75rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      padding: 5rem 4rem;
      background-color: ${colors.grey100};
      order: ${alignImageRight ? '1' : '2'};
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      padding: 11.5rem 5.5rem;
    }
  `}
`

const SCImage = styled.div<{ imageUrl: string; alignImageRight: boolean }>`
  ${({ imageUrl, alignImageRight, theme: { breakpoints } }) => css`
    background-image: url(${imageUrl});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.75rem;
    background-position: center;
    height: 29rem;
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      order: ${alignImageRight ? '2' : '1'};
      height: auto;
    }
  `}
`

const SCDescription = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    ${Typography.pBody};
    color: ${colors.black400};
    @media screen and (max-width: ${breakpoints.tabletMax}) {
      margin-bottom: 2rem;
    }
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      width: 70%;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      width: 100%;
    }
  `}
`

const SCHeaderLabel = styled.span`
  ${Typography.eyebrow}
`

const SCHeaderH1 = styled.h1`
  ${Typography.h1}
  margin: 1.5rem 0;
`
